import React from 'react'

export default function ControlSideBar() {
    return (
        <div>
            {/* <!-- Control Sidebar --> */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* <!-- Control sidebar content goes here --> */}
                <div className="p-3">
                    <h5>Title</h5>
                    <p>Sidebar content</p>
                </div>
            </aside>
            {/* <!-- /.control-sidebar --> */}
        </div>
    )
}
