import React, { useState, useEffect } from "react";
import MainSideBarContainer from "./MainSideBarContainer";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../img/Profile Admin.png";
// import uploadImg from "../img/upload.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Compressor from "compressorjs";
import S3 from "react-aws-s3/dist/react-aws-s3";
import moment from "moment";

// import axios from "axios";

window.Buffer = window.Buffer || require("buffer").Buffer;
const ispublishedDropdown = [
  {
    label: "True",
    value: 1,
  },
  {
    label: "False",
    value: 0,
  },
];
function AddBlog() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [blogimg, setBlogimg] = useState([]);
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [isPublished, setisPublished] = useState();
  const [loading, setLoading] = useState(false);
  const date = new Date();
  //error
  const [error, setError] = useState(false);
  const [titleError, settitleError] = useState(false);
  const [descriptionError, setdescriptionError] = useState(false);
  const [isPublishedError, setisPublishedError] = useState(false);
  const user = JSON.parse(localStorage.getItem("MahaReraUser"));

  console.log("User", user);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleSubmit = async () => {
    setLoading(true);
    if (formvalidate()) {
      console.log("Form is Valid");
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let data = {
        createdBy: user.userId,
        title: title,
        isPublished:isPublished,
        description: description,
        blogDate: moment(date).format("YYYY-MM-DD"),
        imgURLs: blogimg,
      };

      const bodyFile = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };

      console.log("form data", data);
      await fetch(process.env.REACT_APP_BASE_URL + `/blog/add`, bodyFile)
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          console.log("add blog response", res);
          navigate("/blog");
          return res;
        })

        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    }
  };

  const formvalidate = () => {
    let IsformValid = true;

    if (!title) {
      IsformValid = false;
      settitleError(true);
    } else if (title.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }
    if (!description) {
      IsformValid = false;
      setdescriptionError(true);
    }
    if (!isPublished) {
      IsformValid = false;
      setisPublishedError(true);
    }

    return IsformValid;
  };

  const handleChange = async (e) => {
    if (e.target.files.length) {
      console.log("ENV", process.env.REACT_APP_BUCKET_NAME);
      const img = URL.createObjectURL(e.target.files[0]);
      uploadImageToS3(e, "BlogImg");
    }
  };

  const uploadImageToS3 = (event, type) => {
    setLoader(true);
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + type,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    let fileInput = false;
    const image = event.target.files[0];
    console.log("image data before compression-------------- ", image);
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.7,
        success: (compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = getTimeStamp + "_" + "bawd";
          console.log("------- filename image upload --------", newFileName);

          const ReactS3Client = new S3(config);

          ReactS3Client.uploadFile(compressedResult, newFileName)
            .then((res) => {
              blogimg.push(res.location);
              setBlogimg(blogimg);
              console.log("uploded image", res);
              console.log("image uploaded successfully!!!!");
              setLoader(false);
            })
            .catch((err) => {
              console.error(err);
            });
        },
      });
      console.log("--------------- Upload image -----------");
    }
  };

  

  return (
    <>
      <MainSideBarContainer />
      <div>
        {loading ? (
          <div
            className="spinner-border text-primary"
            style={{ width: "30px", marginLeft: "750px" }}
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="content-wrapper">
            {/* <ContentWrapper headContent='Users' myCompo="" /> */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <button
                      className="btn btn-dark button"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <h1 className="m-0"></h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="container-fluid">
                <div
                  className="container-fluid pt-4 px-4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Card
                    className="shadow-lg"
                    style={{
                      margin: 10,
                      width: "80%",
                      borderRadius: 10,
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <h1 className="mt-3">ADD BLOG</h1>
                    <Card.Body>
                      <div>
                        <div>
                          <div
                            className="blog-Image mt-3"
                            style={{ position: "relative" }}
                          >
                            <img
                              className="image-fluid"
                              style={{ width: 200, height: 200 }}
                              src={
                                blogimg.length === 0 ? DefaultImage : blogimg[0]
                              }
                            />
                            <input
                              accept="image/*"
                              type="file"
                              onChange={handleChange}
                              style={{ marginLeft: 10 }}
                            />
                            {/* <div style={{ position:'absolute',backgroundColor:'transparent',height:'100%',width:'100%',zIndex:2 }}>
                                                       <img src={uploadImg} style={{ width:100,height:100}} />
                                                    </div> */}
                          </div>
                          <div className="blog-title mt-3">
                            <label style={{ fontSize: 20, letterSpacing: 1 }}>
                              Title
                            </label>
                            <input
                              className="form-control"
                              placeholder="Title"
                              onChange={(e) => setTitle(e.target.value)}
                              required={true}
                            />
                          </div>
                          {titleError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Title
                            </span>
                          ) : null}

                          <div className="Is publish mt-3">
                            <label style={{ fontSize: 20, letterSpacing: 1 }}>
                              Is Publish
                            </label>
                            {/* <input className='form-control' placeholder='couponType' onChange={(e) => setcouponType(e.target.value)} required/> */}
                            <select
                              // className="form-select"
                              placeholder="ispublished"
                              onChange={(e) => setisPublished(e.target.value)}
                            >
                              <option value="">--Choose--</option>
                              {ispublishedDropdown.map((item) => {
                                return (
                                  <option
                                    value={item.value}
                                    onChange={(e) =>
                                      setisPublished(e.target.value)
                                    }
                                  >
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            {/* {isPublishedError ? <span style={{ color: 'red', fontSize: 15 }}>*select value</span> : null} */}
                          </div>
                          <div className="quill-Editor mt-3">
                            <label style={{ fontSize: 20, letterSpacing: 1 }}>
                              Description
                            </label>

                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              formats={formats}
                              value={description}
                              onChange={setDescription}
                            />
                          </div>
                          {descriptionError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter Description
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </Card.Body>

                    <button
                      className="btn btn-primary button"
                      style={{
                        fontSize: 20,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                      }}
                      // disabled={loading}
                      onClick={(e) => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default AddBlog;
