import React from 'react'
import img1 from '../img/siilclogo1.jpg'
import { RiQuestionnaireFill } from 'react-icons/ri'
import { MdOutlineLogout } from 'react-icons/md'
//import Payments from './Payments'


export default function MainSideBarContainer(props) {

    const handlelogout = () => {
        localStorage.removeItem("MahaReraUser")
    }

    return (
        <div>
            {/* <!-- Main Sidebar Container --> */}
            <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{backgroundColor: "#090761"}}>
                {/* <!-- Brand Logo --> */}
                <a href="/" className="brand-link">
                    <img src={img1} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: "1" }} />
                    <span className="brand-text font-weight-light">MAHARERA</span>
                </a>

                {/* <!-- Sidebar --> */}
                <div className="sidebar">
                    {/* <!-- Sidebar user panel (optional) --> */}
                    {/* <div className="user-panel mt-4 pb-3 mb-4 d-flex">
                        <div className="image ml-1">
                            <img src={img2} className="img-circle elevation-2" alt="User Image" />
                        </div>
                        <div className="info">
                            <a href="#" className="d-block">Admin Panel</a>
                        </div>
                    </div> */}

                    {/* <!-- SidebarSearch Form --> */}
                    {/* <div className="form-inline">
                        <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw"></i>
                                </button>
                            </div>
                        </div>
                    </div> */}

                    {/* <!-- Sidebar Menu --> */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            {/* <!-- Add icons to the links using the .nav-icon className
                            with font-awesome or any other icon font library --> */}
                            <li className="nav-item mb-2 mt-2">
                                <a href="/" className={`nav-link ${props.active == 'dashboard' ? 'active' : ''}`}  
                                   style={props.active === 'dashboard' ? { backgroundColor: '#f6a607'} : {}}>
                                    <i className="nav-icon fas fb-spin fa-tachometer-alt"></i>
                                    <p>
                                        Dashboard
                                        {/* <i className="right fas fa-angle-left"></i> */}
                                    </p>
                                </a>
                               
                            </li>
                            <li className="nav-item mb-2 mt-2">
                                <a href="/webinar" className={`nav-link ${props.active == 'webinar' ? 'active' : ''}`}
                                   style={props.active === 'webinar' ? { backgroundColor: '#f6a607'} : {}}>
                                    <span style={{color:'white'}}>
                                        <i className={`nav-icon fas ${props.active == 'webinar' ? 'fa-spin' : ''} fa-user-graduate`}></i></span>
                                    <p>
                                        Webinar
                                    </p>
                                </a>
                            </li>
                            <li className="nav-item mb-2 mt-2">
                                <a href="/contactedUs" className={`nav-link ${props.active == 'contactedus' ? 'active' : ''}`}
                                   style={props.active === 'contactedus' ? { backgroundColor: '#f6a607'} : {}}>
                                    <i className={`nav-icon fas ${props.active == 'contactedus' ? 'fa-spin' : ''} fa-id-badge`}></i>
                                    <p>
                                        Contacted Us
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </a>
                            </li>
                            <li className="nav-item mb-2 mt-2">
                                <a href="payments" className={`nav-link ${props.active == 'payments' ? 'active' : ''}`}
                                   style={props.active === 'payments' ? { backgroundColor: '#f6a607'} : {}}>
                                    <i className={`nav-icon fas ${props.active == 'Payments' ? 'fa-spin' : ''} fa-credit-card`}></i>
                                    <p>
                                        Payments
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </a>
                            </li>

                            <li className="nav-item mb-2 mt-2">
                                <a href="/appliedcouponlist" className={`nav-link ${props.active == 'appliedcouponlist' ? 'active' : ''}`}
                                style={props.active === 'appliedcouponlist' ? { backgroundColor: '#f6a607'} : {}}>
                                <i className={`nav-icon fas ${props.active == 'appliedcouponlist' ? 'fa-spin' : ''} fa-bars`}></i> 
                              
                                    <p>
                                    Applied Coupon List
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </a>
                            </li>

    

                            <li className="nav-item mb-2 mt-2">
                                <a href="/batch" className={`nav-link ${props.active == 'batch' ? 'active' : ''}`}
                                  style={props.active === 'batch' ? { backgroundColor: '#f6a607'} : {}}>
                                    {console.log("dhddddd",props.active)}
                                    <i className={`nav-icon fas ${props.active == 'batch' ? 'fa-spin' : ''} fa-bars`}></i>
                                    <p>
                                        Batch
                                        {/* <span className="right badge badge-danger">New</span> */}
                                        {/* <i className={`nav-icon fas ${props.active == 'blog' ? 'fa-spin' : ''} fa-blog`}></i> */}
                                    </p>
                                </a>
                            </li>
      

                            <li className="nav-item mb-2 mt-2">
                                <a href="/users" className={`nav-link ${props.active == 'users' ? 'active' : ''}`} 
                                   style={props.active === 'users' ? { backgroundColor: '#f6a607'} : {}}>
                                    <i className={`nav-icon fas ${props.active == 'users' ? 'fa-spin' : ''}  fa-user`}></i>
                                    <p>
                                        Users
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </a>
                            </li>




                            {/* <li className="nav-item mb-2 mt-2">
                                <a href="/batch" className={`nav-link ${props.active == 'batch' ? 'active' : ''}`}>
                                    <i className="nav-icon fas fb-spin  fa-user"></i>
                                    <p>
                                        Batch
                                   </p>
                                </a>
                            </li> */}
                            <li className="nav-item mb-2 mt-2">
                                <a href="/blog" className={`nav-link ${props.active == 'blog' ? 'active' : ''}`}
                                   style={props.active === 'blog' ? { backgroundColor: '#f6a607'} : {}}>
                                    <i className={`nav-icon fas ${props.active == 'blog' ? 'fa-spin' : ''} fa-blog`}></i>
                                    <p>
                                        Blog
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </a>
                            </li>
                            <li className="nav-item mb-2 mt-2">
                                <a href="/enquiry-form" className={`nav-link ${props.active == 'enquiryform' ? 'active' : ''}`}
                                   style={props.active === 'enquiryform' ? { backgroundColor: '#f6a607'} : {}}>
                                    {/* <i className="nav-icon fas fa-blog"></i> */}
                                    <i className={`nav-icon fas ${props.active == 'enquiryform' ? 'fa-spin' : ''} fa-question-circle`}></i> 
                                    {/* <RiQuestionnaireFill style={{ fontSize:24,marginRight:5 }} /> */}
                                    <p>
                                        Enquiry Form
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </a>
                            </li>

                            <li className="nav-item mb-2 mt-2">
                                <a href="/couponlist" className={`nav-link ${props.active == 'coupon' ? 'active' : ''}`}
                                   style={props.active === 'coupon' ? { backgroundColor: '#f6a607'} : {}}>
                                <i className={`nav-icon fas ${props.active == 'coupon' ? 'fa-spin' : ''} fa-bars`}></i> 
                              
                                    <p>
                                    Coupon List
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </a>
                            </li>
                            
                            <li className="nav-item mb-2 mt-2">
                                <a href="/Courselist" className={`nav-link ${props.active == 'course' ? 'active' : ''}`}
                                   style={props.active === 'course' ? { backgroundColor: '#f6a607'} : {}}>
                                <i className={`nav-icon fas  ${props.active == 'course' ? 'fa-spin' : ''} fa-bars`}></i>
                                    {/* <i className="nav-icon fas fa-blog"></i> */}
                                    {/* <RiQuestionnaireFill style={{ fontSize:24,marginRight:5 }} /> */}
                                    <p>
                                   Course List
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </a>
                            </li>

                            <li className="nav-item mb-2 mt-2">
                                <a href="/GoogleLink" className={`nav-link ${props.active == 'googlelink' ? 'active' : ''}`}
                                   style={props.active === 'googlelink' ? { backgroundColor: '#f6a607'} : {}}>
                                <i className={`nav-icon fas  ${props.active == 'googlelink' ? 'fa-spin' : ''} fa-question-circle`}></i>
                                    {/* <i className="nav-icon fas fa-blog"></i> */}
                                    {/* <RiQuestionnaireFill style={{ fontSize:24,marginRight:5 }} /> */}
                                    <p>
                                            Google Link
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </a>
                            </li>

                            <li className="nav-item mb-2 mt-2">
                                <a href="/login" className={`nav-link ${props.active == 'logout' ? 'active' : ''}`} onClick={ () => handlelogout() } 
                                   style={props.active === 'logout' ? { backgroundColor: '#f6a607'} : {}} >
                                    {/* <i className="nav-icon fas fa-blog"></i> */}
                                    <MdOutlineLogout style={{ fontSize:24,marginRight:5 }} />
                                    <p>
                                        Logout
                                        {/* <span className="right badge badge-danger">New</span> */}
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    {/* <!-- /.sidebar-menu --> */}
                </div>
                {/* <!-- /.sidebar --> */}
            </aside>
        </div>
    )
}
