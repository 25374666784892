import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useState } from 'react'
import { MdSendToMobile } from 'react-icons/md'

function ForgotPassword() {
  const [number, setnumber] = useState('')
  const [newOTP,setNewOTP] = useState('')
  const [error, setError] = useState(false)
  const [isotp, setIsotp] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {


  }, [])

  const handleOTP = async () => {

    if (number.length === 10) {
      setError(false)
      setIsotp(true)

      let myHeaders = new Headers()
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

      let urlencoded = new URLSearchParams();
      urlencoded.append("mobile",number)

      let requestoptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    }


      await fetch(process.env.REACT_APP_BASE_URL+"/user/sendotp",requestoptions).then(response => response.json()).then((res) => {

        if(res.message === "Otp Send Successfully"){
          setError(false)
      }
      else {
          setError(true)
      }  
        return res;
    })
    }
    else
      setError(true)
  }

  const verifyOTP = async () => {

    let myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

      let urlencoded = new URLSearchParams();
      urlencoded.append("mobile",number)
      urlencoded.append("newLoginOtp",newOTP)

      let requestoptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    }

      await fetch(process.env.REACT_APP_BASE_URL+"/user/verifyotp",requestoptions).then(response => response.json()).then((res) => {
        if(res.message === "Login Successfully"){
          setError(false)
          navigate("/change-password",{state:res.user.mobile})
      }
      else {
          setError(true)
      }  
        return res;
    })
  }

  console.log("Mobile Number", number)
  return (
    <div className='container-fluid' style={{ height: '100vh', display: 'flex', justifyContent: 'center', backgroundColor: '#343a40', alignItems: 'center' }}>
      <Card style={{ backgroundColor: 'white', padding: 30, fontSize: 20, width: 400 }} >
        <Card.Header><h2 style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold' }}>Forgot Password</h2></Card.Header>
        <Card.Body>
          <Form >
            <Form.Group className="mb-3">
              <Form.Label>Mobile Number</Form.Label>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid ', borderColor: '#e8f0fe', backgroundColor: '#e8f0fe', borderRadius: 5 }}>
                <Form.Control
                  type="number"
                  placeholder="Enter Mobile Number"
                  value={number}
                  onChange={(e) => {
                    e.preventDefault()
                    setnumber(e.target.value)
                  }} />
                <MdSendToMobile style={{ fontSize: 26, marginLeft: 5, marginRight: 5,cursor:'pointer' }} onClick={(e) => { e.preventDefault();handleOTP() }} />
              </div>
              <span>{error ? 'Invalid Mobile Number' : null}</span>
            </Form.Group>
            {isotp ? <div>
              <Form.Group className="mb-3">
                <Form.Label>Enter OTP Sent to your Mobile Number</Form.Label>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid ', borderColor: '#e8f0fe', backgroundColor: '#e8f0fe', borderRadius: 5 }}>
                  <Form.Control
                    type="number"
                    placeholder="Enter OTP"
                    value={newOTP}
                    onChange={ (e) => { e.preventDefault();setNewOTP(e.target.value) } }
                  />
                </div>
              </Form.Group>
              <Button variant="primary" type="submit" className='w-100' onClick={ (e) => { e.preventDefault();verifyOTP() } }>
                Verify OTP
              </Button>
            </div> : null}
            { error ? <h5 style={{ color:'red' }}>Invalid Credentials</h5> : null }
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ForgotPassword