import React, { useState, useEffect } from 'react'
import MainSideBarContainer from './MainSideBarContainer'
import { Card, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultImage from '../img/Profile Admin.png'
import uploadImg from '../img/upload.png'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import Compressor from 'compressorjs';
import S3 from 'react-aws-s3/dist/react-aws-s3'
import moment from 'moment'
import axios from "axios";



window.Buffer = window.Buffer || require('buffer').Buffer

function Viewcoupon() {

    const navigate = useNavigate()
    // const [couponName, setcouponName] = useState()
    // const [startDate, setstartDate] = useState()
    // const [endDate,setendDate] = useState()
    // const [couponType, setcouponType] = useState()
    // const [couponvalue, setcouponvalue] = useState()
    const location = useLocation();
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [couponName, setcouponName] = useState()
    const [startDate, setstartDate] = useState()
    const [endDate, setendDate] = useState()
    const [couponType, setcouponType] = useState()
    const [couponvalue, setcouponvalue] = useState()
    const [courseName, setcourseName] = useState()
    const date = new Date()

    useEffect(() => {
        console.log("Prop", location.state)
        getdata()
    }, [])

    const getdata = async () => {
        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

        let urlencoded = new URLSearchParams();

        urlencoded.append("couponId", location.state.couponId)


        let requestoptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        await fetch(process.env.REACT_APP_BASE_URL + "/coupon/viewsinglecoupon", requestoptions).then(response => response.json()).then((res) => {
            console.log("-------view coupon API response----------", res)
            setData(res);
            // setcouponName(res.couponName)

            // navigate("/blog")
            return res;
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    }









    // const handleChange = async e => {
    //     if (e.target.files.length) {
    //         console.log("ENV", process.env.REACT_APP_BUCKET_NAME)
    //         const img = URL.createObjectURL(e.target.files[0]);
    //         uploadImageToS3(e, "BlogImg")
    //     }
    // };



    const uploadImageToS3 = (event, type) => {
        setLoader(true)
        const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            dirName: process.env.REACT_APP_DIR_NAME + type,
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            s3Url: process.env.REACT_APP_S3URL
        };

        let fileInput = false;
        const image = event.target.files[0];
        console.log("image data before compression-------------- ", image);
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            new Compressor(image, {
                quality: 0.7,
                success: (compressedResult) => {
                    let getTimeStamp = new Date().getTime();
                    let newFileName = getTimeStamp + "_" + 'bawd';
                    console.log("------- filename image upload --------", newFileName);

                    const ReactS3Client = new S3(config);

                    ReactS3Client.uploadFile(compressedResult, newFileName).then((res) => {
                        // blogimg.push(res.location)
                        // setBlogimg(blogimg);
                        // console.log("uploded image", res);
                        // console.log("image uploaded successfully!!!!");
                        // setLoader(false)
                    }).catch(err => {
                        console.error(err);
                    });
                },
            });
            console.log("--------------- Upload image -----------");
        }
    };

    return (
        <div>
            <MainSideBarContainer />
            <div>
                <div className="content-wrapper">
                    {/* <ContentWrapper headContent='Users' myCompo="" /> */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <button className='btn btn-dark button' onClick={() => navigate(-1)} >Back</button>
                                </div>
                                <div className="col-sm-6">
                                    <h1 className="m-0"></h1>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="container-fluid pt-4 px-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Card
                                    className="shadow-lg"
                                    style={{
                                        margin: 10,
                                        width: '50%',
                                        borderRadius: '5%',
                                        // justifyContent: "center",
                                        // textAlign: "center",
                                        backgroundColor: "whitesmoke"
                                    }}

                                >
                                    <h1 className="mt-0" style={{ justifyContent: "center", textAlign: "center", backgroundColor: '#282c34', color: 'white' }}>VIEW COUPON</h1>
                                    <Card.Body>
                                        <div>
                                            <div>
                                                {/* <div className='blog-Image mt-3' style={{ position: 'relative' }}>
                                                    <img className='image-fluid' style={{ width: 200, height: 200 }} src={blogimg.length === 0 ? DefaultImage : blogimg[0]} />
                                                    <input accept='image/*' type="file" onChange={handleChange} style={{ marginLeft: 10 }} />
                                                   
                                                </div> */}
                                                <Table style={{ fontSize: 18, marginTop: 20 }} >
                                                    <tbody >


                                                        <tr>
                                                            <td style={{ fontWeight: 'bold' }}>Coupon Name: </td>
                                                            <td >{data.couponName}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ fontWeight: 'bold' }}>Coupon Code: </td>
                                                            <td >{data.couponCode}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: 'bold' }}>Start Date:</td>
                                                            <td >{moment(data.startDate).format("DD MMM YYYY ")}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: 'bold' }}>End Date: </td>
                                                            <td >{moment(data.endDate).format("DD MMM YYYY")}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: 'bold' }}>CouponType: </td>
                                                            <td >{data.couponType}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: 'bold' }}>Coupon Value: </td>
                                                            <td >{data.couponvalue}</td>
                                                        </tr>

                                                    </tbody>
                                                </Table>


                                            </div>
                                        </div>
                                    </Card.Body>

                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Viewcoupon;