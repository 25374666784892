import React, { useState, useEffect } from 'react'
import MainSideBarContainer from './MainSideBarContainer'
import moment from 'moment'
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import Modal from "react-bootstrap/Modal";
import { Card, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";



const sortIcon = <ArrowDownward />;
export default function Payments(props) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);


    const [filteredData, setFilteredData] = useState()
    const [propsData, setPropsData] = useState()

    const [show, setShow] = useState(false);
    const [filterCode, setFilterCode] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handle = (row) => {
        handleShow()

        setPropsData(row)

    }


    useEffect(() => {
        getdata()
        console.log("Propppp")

    }, [])

    const getdata = async () => {
        setLoading(true);

        await fetch(process.env.REACT_APP_BASE_URL + '/transaction/list', {}).then(response => response.json()).then(res => {
            console.log("--------transaction list-----------",res)
            setLoading(false);
            setData(res.data)
            propsData(res.data);
            setFilteredData(res.data);
            return res
        })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
            });
    }

    console.log("Payment list", data)



    const filterData = (char) => {
        // setLoader(true);
        // console.log(char)
        // console.log("Charracter Array", filteredData)
        let f = filteredData?.filter(item => item?.fullname?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
            item?.course_name?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            item?.mobile?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            item?.email?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            dayjs(item?.createdAt).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            //   dayjs(item?.endDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase())||
            item?.id?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            item?.batch?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            item?.status?.toString().toLowerCase()?.startsWith(char?.toLowerCase())
            //   item?.gramsevakhName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            //   item?.serverNo?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) 
        )
        setData(f)
        console.log("MyData", f)
        // setLoader(false);
    }


    const columns = [

        {
            name: 'Id',
            selector: row => row.transactionsId,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.fullname,
            sortable: true,

        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: 'Course Name',
            selector: row => row.course_name,
            sortable: true,
            wrap: true,
            width: '10%',
        },
        {
            name: 'Batch',
            selector: row => row.batch,
            sortable: true,
            wrap: true,
            width: '10%',
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Payment Date',
            selector: row => moment(row.createdAt).format("DD MMM YYYY hh:mm a"),
            sortable: true,
        },
        {
            name: 'Coupon Code',
            selector: row => row.couponCode,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => <div >
                <button className='btn btn-sm btn-outline-dark mt-1 ml-1 button' onClick={() => handle(row)}  >
                    View
                </button>
            </div >,
            sortable: true,
        },

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
                taxtAlign: 'center',
                justifyContent: 'center',
            },
        },
        columns: {
            style: {
                taxtAlign: 'center',
                justifyContent: 'center',
            }


        },


        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                color: 'red',
                fontSize: '16px',
                taxtAlign: 'center',
                justifyContent: 'center'

            },
        },
        cells: {
            style: {
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '10px',
                fontSize: '16px',
                taxtAlign: 'center',
                justifyContent: 'center'
            },
        },
    };

    const downloadExcel = (customHeadings) => {
        let dataSet = [];
        console.log("dataset", dataSet)
        dataSet = customHeadings;
        const worksheet = XLSX.utils.json_to_sheet(dataSet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "PaymentReport.xlsx");
    };

    const CustomExcel = () => {

        const customHeadings = data.length > 0 && data.map(item => ({
            "Transaction id": item.transactionsId ? item.transactionsId : '-',
            "Name": item.fullname ? item.fullname : '-',
            "Mobile": item.mobile ? item.mobile : '-',
            "Email": item.email ? item.email : '-',
            "Coupon Code": item.couponCode ? item.couponCode : '-',
            "Batch": item.batch ? item.batch : '-',
            "Course Name": item.course_name ? item.course_name : '-',
            "Payment Date": item.createdAt ? moment(item.createdAt).format("DD MMM YYYY hh:mm a") : '-',
            "Status": item.status ? item.status : '-'
        }))
        console.log("Customm Excel", customHeadings)
        downloadExcel(customHeadings)
    };

    const conditionalRowStyles = [
        {
            when: row => row.status == 'success',
            style: {
                backgroundColor: 'white',
                color: '#00b33c',
                '&:hover': {
                    cursor: 'pointer',
                },
            }
        },
        {
            when: row => row.status == 'dropped',
            style: {
                backgroundColor: 'white',
                color: '#ff4d4d',
                '&:hover': {
                    cursor: 'pointer',
                },
            }
        },
        {
            when: row => row.status == 'pending',
            style: {
                backgroundColor: 'white',
                color: '#ff9933',
                '&:hover': {
                    cursor: 'pointer',
                },
            }
        },
        {
            when: row => row.status == 'failure',
            style: {
                backgroundColor: 'white',
                color: '#ff4d4d',
                '&:hover': {
                    cursor: 'pointer',
                },
            }
        },
        {
            when: row => row.status == 'userCancelled',
            style: {
                backgroundColor: 'white',
                color: '#000099',
                '&:hover': {
                    cursor: 'pointer',
                },
            }
        },

    ];

    return (
        <div>

            <MainSideBarContainer active="payments" />
            <div>
                <div className="content-wrapper">

                    {/* <ContentWrapper headContent='Users' myCompo="" /> */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Payment List</h1>
                                </div>
                                <div className='col-sm-6'>
                                    <button className='btn btn-dark float-right mr-5 mt-1 button' onClick={CustomExcel}>Export Data</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="container-fluid pt-4 px-4">
                                <div className=''>
                                    <input type="text" placeholder="Search" onChange={(e) => { filterData(e.target.value) }} />
                                    <div className="row bg-light rounded justify-content-center p-3 mx-0">
                                        {loading ? (
                                            <div className="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <div className='col-md-12 lm-6 table-bordered' style={{paddingLeft:'0px',paddingRight:'0px',}}>
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    sortIcon={sortIcon}
                                                    pagination
                                                    responsive
                                                    //customStyles={customStyles}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                // theme="solarized"
                                                />
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal style={{ marginTop: "48px", height: "90%" }} show={show} onHide={handleClose} size="lg"  >
                        <Modal.Header closeButton={true}>
                            <Modal.Title>Payments</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* {console.log("Modal data", appliedCoupon)}
                            {console.log("filter Data", appliedCoupon.filter(item => item?.couponName === filterCode))} */}
                            {/* {
                                // let filtercoupon = appliedCoupon.filter( item => item?.couponName === filterCode);
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    sortIcon={sortIcon}
                                    pagination
                                    responsive
                                    customStyles={customStyles}
                                // theme="solarized"
                                />
                            } */}

                            <div className="content">
                                <div className="container-fluid">
                                    <div className="container-fluid pt-4 px-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Card
                                            className="shadow-lg"
                                            style={{
                                                margin: 10,
                                                width: '90%',
                                                borderRadius: '5%',
                                                // justifyContent: "center",
                                                // textAlign: "center",
                                                backgroundColor: "whitesmoke"
                                            }}

                                        >
                                            <h2 className="mt-0" style={{ justifyContent: "center", textAlign: "center", backgroundColor: '#282c34', color: 'white' }}>View Payment Details</h2>
                                            <Card.Body>
                                                <div>
                                                    <div>
                                                        {/* <div className='blog-Image mt-3' style={{ position: 'relative' }}>
                                                    <img className='image-fluid' style={{ width: 200, height: 200 }} src={blogimg.length === 0 ? DefaultImage : blogimg[0]} />
                                                    <input accept='image/*' type="file" onChange={handleChange} style={{ marginLeft: 10 }} />
                                                   
                                                </div> */}
                                                        <Table style={{ fontSize: 18, marginTop: 20 }} >
                                                            <tbody >
                                                                <tr>
                                                                    <td style={{ fontWeight: 'bold' }}> Name: </td>
                                                                    <td >{propsData?.fullname}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style={{ fontWeight: 'bold' }}>Mobile: </td>
                                                                    <td >{propsData?.mobile}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td style={{ fontWeight: 'bold' }}>Course Name: </td>
                                                                    <td >{propsData?.course_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontWeight: 'bold' }}>Batch:</td>
                                                                    <td >{propsData?.batch}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontWeight: 'bold' }}>Payment Date: </td>
                                                                    <td >{moment(propsData?.createdAt).format("DD MMM YYYY")}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontWeight: 'bold' }}>Coupon Code: </td>
                                                                    <td >{propsData?.couponCode}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontWeight: 'bold' }}>Status: </td>
                                                                    <td >{propsData?.status}</td>
                                                                </tr>

                                                            </tbody>
                                                        </Table>


                                                    </div>
                                                </div>
                                            </Card.Body>

                                        </Card>
                                    </div>
                                </div>
                            </div>



                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>

        </div>
    )
}
