import React, { useState, useEffect } from "react";
import MainSideBarContainer from "./MainSideBarContainer";
import { Card } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultImage from "../img/Profile Admin.png";
import uploadImg from "../img/upload.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Compressor from "compressorjs";
import S3 from "react-aws-s3/dist/react-aws-s3";
import moment from "moment";
import axios from "axios";

window.Buffer = window.Buffer || require("buffer").Buffer;
const ispublishedDropdown = [
  {
    label: "True",
    value: 1,
  },
  {
    label: "False",
    value: 0,
  },
];

function EditBlog() {
  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState();
  const [blogimg, setBlogimg] = useState();
  const [description, setDescription] = useState();
  const [loader, setLoader] = useState(false);
  const [isPublished, setisPublished] = useState();
  const [data, setData] = useState([]);
  const date = new Date();
  console.log("blogimages", blogimg);

  useEffect(() => {
    console.log("Props", location.state);
    getdata();
  }, []);

  const user = JSON.parse(localStorage.getItem("MahaReraUser"));

  console.log("Usersssss", user);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const getdata = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();

    urlencoded.append("blogId", location.state.blogId);

    let requestoptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    await fetch(
      process.env.REACT_APP_BASE_URL+"/blog/viewsingle",
      requestoptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("-------view blog API response----------", res);
        setData(res);
        setTitle(res[0].title);
        setDescription(res[0].description);
        setBlogimg(res[0].blogimg);

        // navigate("/blog")
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleUpdate = async () => {
    // console.log("Title", title)
    // console.log("description", location.state.description)
    // console.log("bbbblogimg", location.state.blogsimgs[0]?.url)
    // console.log("Date",moment(date).format("DD MMM YYYY"))
    // console.log("idddd", location.state.blogId)

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("title", title);
    urlencoded.append("description", description);
    urlencoded.append("isPublished", isPublished);
    urlencoded.append("blogDate", moment(date).format("YYYY-MM-DD"));
    urlencoded.append("imgURLs", blogimg);
    urlencoded.append("blogId", location.state.blogId);

    // let data = {
    //     // "createdBy":user.id,
    //     "title": title,
    //     "description": description,
    //     "blogDate": moment(date).format("YYYY-MM-DD"),
    //     "imgURLs": blogimg,
    //      "blogId": user.blogId

    // }

    let requestoptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BASE_URL+"/blog/update",
      requestoptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("-------Edit Blog API response----------", res.data);
        navigate("/blog");
        return res.data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDelete = async () => {
    // console.log("Title", title)
    // console.log("description", description)
    // console.log("bbbblogimg", blogimg)
    // console.log("Date",moment(date).format("DD MMM YYYY"))

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();

    urlencoded.append("blogId", location.state.blogId);

    let requestoptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BASE_URL+"/blog/delete",
      requestoptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("-------delete Blog API response----------", res);
        navigate("/blog");
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleChange = async (e) => {
    if (e.target.files.length) {
      console.log("ENV", process.env.REACT_APP_BUCKET_NAME);
      const img = URL.createObjectURL(e.target.files[0]);
      uploadImageToS3(e, "BlogImg");
    }
  };

  const uploadImageToS3 = (event, type) => {
    setLoader(true);
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + type,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    let fileInput = false;
    const image = event.target.files[0];
    console.log("image data before compression-------------- ", image);
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.7,
        success: (compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = getTimeStamp + "_" + "bawd";
          console.log("------- filename image upload --------", newFileName);

          const ReactS3Client = new S3(config);

          ReactS3Client.uploadFile(compressedResult, newFileName)
            .then((res) => {
              blogimg.push(res.location);
              setBlogimg(blogimg);
              console.log("uploded image", res);
              console.log("image uploaded successfully!!!!");
              setLoader(false);
            })
            .catch((err) => {
              console.error(err);
            });
        },
      });
      console.log("--------------- Upload image -----------");
    }
  };

  return (
    <div>
      <MainSideBarContainer />
      <div>
        <div className="content-wrapper">
          {/* <ContentWrapper headContent='Users' myCompo="" /> */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <button className="btn btn-dark button" onClick={() => navigate(-1)}>
                    Back
                  </button>
                </div>
                <div className="col-sm-6">
                  <h1 className="m-0"></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div
                className="container-fluid pt-4 px-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  className="shadow-lg"
                  style={{
                    margin: 10,
                    width: "80%",
                    borderRadius: 10,
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <h1 className="mt-3">EDIT BLOG</h1>
                  <Card.Body>
                    <div>
                      <div>
                        <div
                          className="blog-Image mt-3"
                          style={{ position: "relative" }}
                        >
                          {/* <img className='image-fluid' style={{ width: 200, height: 200 }} src={blogimg.length === 0 ? DefaultImage : blogimg[1]} /> */}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={handleChange}
                            style={{ marginLeft: 10 }}
                          />
                          {/* <div style={{ position:'absolute',backgroundColor:'transparent',height:'100%',width:'100%',zIndex:2 }}>
                                                       <img src={uploadImg} style={{ width:100,height:100}} />
                                                    </div> */}
                        </div>
                        <div className="blog-title mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Title
                          </label>

                          <input
                            className="form-control"
                            placeholder="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>

                        <div className="Is publish mt-3">
                          <label
                            style={{
                              fontSize: 20,
                              letterSpacing: 1,
                              marginLeft: -570,
                            }}
                          >
                            Is Publish
                          </label>
                          {/* <input className='form-control' placeholder='couponType' onChange={(e) => setcouponType(e.target.value)} required/> */}
                          <select
                            // className="form-select"
                            placeholder="ispublished"
                            onChange={(e) => setisPublished(e.target.value)}
                          >
                            <option value="">--Choose--</option>
                            {ispublishedDropdown.map((item) => {
                              return (
                                <option
                                  value={item.value}
                                  onChange={(e) =>
                                    setisPublished(e.target.value)
                                  }
                                >
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="quill-Editor mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Description
                          </label>

                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={description}
                            onChange={setDescription}
                          />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                  <button
                    className="btn btn-primary button"
                    style={{
                      fontSize: 20,
                      borderBottomRightRadius: 5,
                      borderBottomLeftRadius: 5,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpdate();
                    }}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-primary mt-2 button"
                    style={{
                      fontSize: 20,
                      borderBottomRightRadius: 5,
                      borderBottomLeftRadius: 5,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleDelete();
                    }}
                  >
                    Delete
                  </button>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBlog;
