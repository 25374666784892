import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useState } from 'react'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { getSuggestedQuery } from '@testing-library/react'

function ChangePassword() {

  const [Password, setPassword] = useState('')
  const [user, setUser] = useState('')
  const [confirmPass, setconfirmPass] = useState('')
  const [error, setError] = useState(false)
  const [isvisible, setIsvisible] = useState(false)
  const [isConfirmpass, setisConfirmpass] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    console.log("Props", location.state)
    console.log("User", user)
    getUser()
  }, [])

  const getUser = async () => {
    let myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    let urlencoded = new URLSearchParams();
    urlencoded.append("mobile", location.state)

    let requestoptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    await fetch(process.env.REACT_APP_BASE_URL+"/user/getuser", requestoptions).then(response => response.json()).then((res) => {
      if (res.data !== null) {
        setUser(res.data)
      }
      else {
        setError(true)
      }
      return res;
    })
  }

  const handleChangePassword = async () => {
    let myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    let urlencoded = new URLSearchParams();
    urlencoded.append("id",user.id)
    urlencoded.append("newPassword",Password)

    let requestoptions = {
      method: 'PUT',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    await fetch(process.env.REACT_APP_BASE_URL+"/user/changepassword", requestoptions).then(response => response.json()).then((res) => {
      if (res.message === "change password Successfully") {
        localStorage.setItem("User", JSON.stringify(res.data))
        navigate("/")
      }
      else {
        setError(true)
      }
      return res;
    })

  }

  return (
    <div className='container-fluid' style={{ height: '100vh', display: 'flex', justifyContent: 'center', backgroundColor: '#343a40', alignItems: 'center' }}>
      <Card style={{ backgroundColor: 'white', padding: 30, fontSize: 20, width: 400 }} >
        <Card.Header><h2 style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold' }}>Change Password</h2></Card.Header>
        <Card.Body>
          <Form >
            <Form.Group className="mb-3">
              <Form.Label>Enter Password</Form.Label>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid ', borderColor: '#e8f0fe', backgroundColor: '#e8f0fe', borderRadius: 5 }}>
                <Form.Control
                  type={isvisible ? 'text' : "password"}
                  placeholder="Enter Password"
                  value={Password}
                  onChange={(e) => {
                    e.preventDefault()
                    setPassword(e.target.value)
                  }} />
                {isvisible ? <MdVisibilityOff style={{ fontSize: 26, marginLeft: 5, marginRight: 5, cursor: 'pointer' }} onClick={() => setIsvisible(!isvisible)} /> : <MdVisibility style={{ fontSize: 26, marginLeft: 5, marginRight: 5, cursor: 'pointer' }} onClick={() => setIsvisible(!isvisible)} />}
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid ', borderColor: '#e8f0fe', backgroundColor: '#e8f0fe', borderRadius: 5 }}>
                <Form.Control
                  type={isConfirmpass ? 'text' : "password"}
                  placeholder="Enter Password"
                  value={confirmPass}
                  onChange={(e) => {
                    e.preventDefault()
                    setconfirmPass(e.target.value)
                  }} />
                {isConfirmpass ? <MdVisibilityOff style={{ fontSize: 26, marginLeft: 5, marginRight: 5, cursor: 'pointer' }} onClick={() => setisConfirmpass(!isConfirmpass)} /> : <MdVisibility style={{ fontSize: 26, marginLeft: 5, marginRight: 5, cursor: 'pointer' }} onClick={() => setisConfirmpass(!isConfirmpass)} />}
              </div>
            </Form.Group>
            {Password !== '' && confirmPass !== '' && Password === confirmPass ? 
              <Button variant="primary" type="submit" className='w-100' onClick={(e) => { e.preventDefault(); handleChangePassword() }}>
                Change Password
              </Button> : <span style={{ color: 'red', fontSize: 17 }} >Password doesn't matched</span>}
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ChangePassword