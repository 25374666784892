import React from 'react'

export default function Footer() {
    return (
        <div>
            {/* <!-- Footer Start --> */}
            <footer className="main-footer text-center">
                {/* <!-- To the right --> */}
                {/* <div className="float-right d-none d-sm-inline">
                    Anything you want
                </div> */}
                {/* <!-- Default to the left --> */}
                <strong>Copyright &copy; 2022-23 <a href="https://admin.mahareratraining.com/">SIILC</a>.</strong> All rights reserved.
            </footer>
            {/* <!-- Footer End --> */}
        </div>
    )
}
