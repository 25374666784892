import React, { useState, useEffect } from "react";
import MainSideBarContainer from "./MainSideBarContainer";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultImage from "../img/Profile Admin.png";
import uploadImg from "../img/upload.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Compressor from "compressorjs";
import S3 from "react-aws-s3/dist/react-aws-s3";
import moment from "moment";
import axios from "axios";
import { SystemUpdate } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";

import Button from 'react-bootstrap/Button';

window.Buffer = window.Buffer || require("buffer").Buffer;
const couponDropdown = [
  {
    label: "Value",
    value: 1,
  },
  {
    label: "Percentage",
    value: 2,
  },
];
const isPublishDropdown = [
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];

function EditCoupon() {
  const navigate = useNavigate();

  const location = useLocation();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [couponName, setcouponName] = useState();
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [couponType, setcouponType] = useState();
  const [couponvalue, setcouponvalue] = useState();
  const [courseId, setcourseId] = useState();
  const [updateBy, setupdateBy] = useState();
  const [couponId, setCouponId] = useState();
  const [isPublish, setIsPublish] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handle = (data) => {
    handleShow()
  }



  const date = new Date();
  //validation
  const [error, setError] = useState(false);
  const [couponError, setcouponError] = useState(false);
  const [courseError, setcourseError] = useState(false);
  const [couponstartdateError, setcouponstartdateError] = useState(false);
  const [couponEnddateError, setcouponEnddateError] = useState(false);
  const [coupontypeError, setcoupontypeError] = useState(false);
  const [couponvalueError, setcouponvalueError] = useState(false);
  const [isPublishError, setIsPublishError] = useState(false);

  useEffect(() => {
    console.log("Props", location.state);
    getdata();
    getcourses();
  }, []);

  const getcourses = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/course/viewallcourses")
      .then((response) => response.json())
      .then((res) => {
        setData(res);
        console.log("courselist", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getdata = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();

    urlencoded.append("couponId", location.state.couponId);

    let requestoptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    await fetch(
      process.env.REACT_APP_BASE_URL + "/coupon/viewsinglecoupon",
      requestoptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("-------view coupon API response----------", res);
        setData(res);
        setcouponName(res.couponName);
        setcouponType(res.couponType);
        setcouponvalue(res.couponvalue);
        setstartDate(moment(res.startDate).format("YYYY-MM-DD"));
        setendDate(moment(res.endDate).format("YYYY-MM-DD"));
        // setendDate(res.updatedBy)
        setcourseId(res.courseId);
        setIsPublish(res.isPublish);

        // navigate("/blog")
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  console.log("start date", startDate);

  const handleUpdate = async () => {
    console.log("STill updating")
    if (formvalidate()) {
      setLoader(true)
      console.log("Form is Valid");

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let formData = new URLSearchParams();
      formData.append("courseId", courseId);
      formData.append("couponName", couponName);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("couponvalue", couponvalue);
      formData.append("couponType", couponType);
      formData.append("isPublish", isPublish);
      formData.append("updateBy", 1)
      formData.append("couponId", location.state.couponId)
      console.log("couponId", location.state.couponId)

      let requestoptions = {
        method: "PUT",
        headers: myHeaders,
        body: formData,
        redirect: "follow"
      }

      await fetch(process.env.REACT_APP_BASE_URL + "/coupon/updatecoupon", requestoptions)
        .then(response => response.json())
        .then((res) => {
          console.log("-------Edit coupon API response----------", res);
          alert('Update successfully.')
          setLoader(false)

          navigate("/couponlist");
          return res;
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoader(false)
        });
    } else {
      console.log("Not valid")
      alert("Invalid Data please check and update !")
    }
  };

  const formvalidate = () => {
    let IsformValid = true;

    if (!couponName) {
      IsformValid = false;
      console.log("1")
      setcouponError(true);
    } else if (couponName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      console.log("1")
      setError(true);
    }

    if (!courseId) {
      IsformValid = false;
      console.log("2")
      setcourseError(true);
    }
    // if (!startDate) {
    //   IsformValid = false;
    //   setcouponstartdateError(true);
    // }
    // if (!endDate) {
    //   IsformValid = false;
    //   setcouponEnddateError(true);
    // }
    if (!couponType) {
      IsformValid = false;
      console.log("3")
      setcoupontypeError(true);
    }
    if (couponvalue !== 0 && !couponvalue) {
      IsformValid = false;
      console.log("4")
      setcouponvalueError(true);
    }
    if (!isPublish) {
      IsformValid = false;
      console.log("5")
      setIsPublishError(true);
    }

    return IsformValid;
  };

  const handleDelete = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();

    urlencoded.append("couponId", location.state.couponId);

    let requestoptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + "/coupon/deletecoupon",
      requestoptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("-------delete Blog API response----------", res);
        navigate("/couponlist");
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <MainSideBarContainer />
      <div>
        <div className="content-wrapper">
          {/* <ContentWrapper headContent='Users' myCompo="" /> */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <button className="btn btn-dark button" onClick={() => navigate(-1)}>
                    Back
                  </button>
                </div>
                <div className="col-sm-6">
                  <h1 className="m-0"></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div
                className="container-fluid pt-4 px-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  className="shadow-lg"
                  style={{
                    margin: 10,
                    width: "60%",
                    borderRadius: 10,
                    // justifyContent: "center",
                    // textAlign: "center",
                  }}
                >
                  <h1
                    className="mt-3"
                    style={{ justifyContent: "center", textAlign: "center", fontSize:"30px" }}
                  >
                    UPDATE COUPON
                  </h1>
                  <Card.Body>
                    <div>
                      <div>
                        {/* <div className='blog-Image mt-3' style={{ position: 'relative' }}>
                                                    <img className='image-fluid' style={{ width: 200, height: 200 }} src={blogimg.length === 0 ? DefaultImage : blogimg[0]} />
                                                    <input accept='image/*' type="file" onChange={handleChange} style={{ marginLeft: 10 }} />
                                                   
                                                </div> */}
                        <div className="coruse-name mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Coupon Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            //  error={couponName.match(/[0-9+@#$&%!~]/)}
                            placeholder="couponName"
                            value={couponName}
                            onChange={(e) => setcouponName(e.target.value)}
                          />
                        </div>
                        {/* {couponError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter coupon Name
                          </span>
                        ) : null} */}
                        <div className="course-description mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Course Id
                          </label>
                          <input
                            className="form-control"
                            placeholder="scoursedId"
                            value={courseId}
                            onChange={(e) => setcourseId(e.target.value)}
                          />
                        </div>
                        {/* {courseError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *please Enter Value
                          </span>
                        ) : null} */}
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-6">
                            <div className="course-description mt-3">
                            <label style={{ fontSize: 20, letterSpacing: 1 }}>
                              Start Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="startDate"

                              value={moment(startDate).format("YYYY-MM-DD")}

                              onChange={(e) => setstartDate(moment(e.target.value).format("YYYY-MM-DD"))}
                            />
                            </div>
                            {/* {couponstartdateError ? (
                              <span style={{ color: "red", fontSize: 15 }}>
                                *Password cannot be empty
                              </span>
                            ) : null} */}
                          </div>
                          <div className="col-lg-6 col-md-6 col-6">
                              
                            <div className="endDate mt-3">
                              <label style={{ fontSize: 20, letterSpacing: 1 }}>
                                End Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="endDate"
                                value={endDate}
                                onChange={(e) => setendDate(e.target.value)}
                              />
                            </div>
                            {/* {couponEnddateError ? (
                              <span style={{ color: "red", fontSize: 15 }}>
                                *Password cannot be empty
                              </span>
                            ) : null} */}
                          </div>
                        </div>
                        
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-6">
                            <div className="coruse-baseprice mt-3">
                            <label style={{ fontSize: 20, letterSpacing: 1 }}>
                              CouponType
                            </label>
                            {/* <input className='form-control' placeholder='couponType' onChange={(e) => setcouponType(e.target.value)} required/> */}
                            <select
                              className="form-select"
                              placeholder="couponType"
                              value={couponType}
                              onChange={(e) => setcouponType(e.target.value)}
                            >
                              <option value="">--Choose--</option>
                              {couponDropdown.map((item) => {
                                return (
                                  <option
                                    value={item.value}
                                    onChange={(e) =>
                                      setcouponType(e.target.value)
                                    }
                                  >
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                            {/* {coupontypeError ? (
                              <span style={{ color: "red", fontSize: 15 }}>
                                *Password select value
                              </span>
                            ) : null} */}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-6">
                            <div className="coruse-offerprice mt-3">
                            <label style={{ fontSize: 20, letterSpacing: 1 }}>
                              Coupon Value
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="couponvalue"
                              value={couponvalue}
                              onChange={(e) => setcouponvalue(e.target.value)}
                              required
                            />
                            {/* {couponvalueError ? (
                              <span style={{ color: "red", fontSize: 15 }}>
                                *Password enter coupon value
                              </span>
                            ) : null} */}
                            </div>
                          </div>
                        </div>            

                        

                        <div className="coruse-baseprice mt-3">
                        <label style={{ fontSize: 20, letterSpacing: 1 }}>
                          Publish Status
                        </label>
                        {/* <input className='form-control' placeholder='couponType' onChange={(e) => setcouponType(e.target.value)} required/> */}
                        <select
                          className="form-select"
                          placeholder="Publish Status"
                          value={isPublish}
                          onChange={(e) => setIsPublish(e.target.value)}
                        >
                          <option value="">--Choose--</option>
                          {isPublishDropdown.map((item) => {
                            return (
                              <option
                                value={item.value}
                                onChange={(e) =>
                                  setIsPublish(e.target.value)
                                }
                              >
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                        {/* {coupontypeError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Password select value
                          </span>
                        ) : null} */}
                      </div>
                        <div className="d-flex m-3" style={{ justifyContent:"space-evenly", marginTop: "50px" }}>
                          {loader ? <CircularProgress color="primary" /> : <button
                            className="btn btn-primary mt-2 button"
                            style={{
                              fontSize: 20,
                              borderBottomRightRadius: 5,
                              borderBottomLeftRadius: 5,
                            }}
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   handleUpdate();
                            // }}
                            onClick={() => {
                              console.log("Hii its update")
                              handleUpdate()
                            }}
                          >
                            Update
                          </button>}
                          <button

                            className="btn btn-primary ml-2 mt-2 button"
                            style={{
                              fontSize: 20,
                              borderBottomRightRadius: 5,
                              borderBottomLeftRadius: 5,
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              handle();
                              // handleDelete();
                              console.log("HIIII its delete")
                            }}
                          >
                            Delete
                          </button>
                        </div>
                        <Modal style={{ marginTop: "48px", height: "90%" }} show={show} onHide={handleClose} size="md"  >


                          <Modal.Body>



                            <h5 style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>Are you sure want to delete</h5>


                            <div className="d-flex m-3" style={{ justifyContent: "center", marginTop: "50px" }}>
                              <Button variant="secondary" style={{ marginRight: "25px" }} onClick={handleDelete}>
                                delete
                              </Button>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </div>
                          </Modal.Body>
                        </Modal>

                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCoupon;
