import React, { useEffect, useState } from 'react'
import MainSideBarContainer from './MainSideBarContainer'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import NOImagehere from '../img/nope-not-here.webp';
import dayjs from 'dayjs';
// import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

const sortIcon = <ArrowDownward />;
export default function Blog() {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [title, setTitle] = useState('')
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState()

    const getdata = async () => {
        setLoading(true);
        await fetch(process.env.REACT_APP_BASE_URL+'/blog/view').then(response => response.json()).then((res) => {
            setLoading(false);
            setData(res);
            setFilteredData(res);
            return res
            setLoading(false);
        })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
            });

    }

    const filterData = (char) => {
        // setLoader(true);
        // console.log(char)
        // console.log("Charracter Array", filteredData)
        let f = filteredData?.filter(item => item?.title?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
          item?.isPublished?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          item?.blogsimgs?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          dayjs(item?.createdAt).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase()) 
        //   dayjs(item?.endDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase())||
          //item?.City?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          //item?.district?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) 
        //   item?.gramsevakhName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
        //   item?.serverNo?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) 
          )
          setData(f)
        console.log("MyData", f)
        // setLoader(false);
      }

    useEffect(() => {
        getdata()
    }, [])

    const columns = [
        {
            name: 'Title',
            selector: row => row?.title,
            sortable: true,
        },
        // {
        //     name: 'Description',
        //     selector: row => row.description,
        //     sortable: true,
        // },
        {
            name: 'Blog IMG',
            selector: row => <div>{ row?.blogsimgs !== undefined ? <a target="_blank" href={row?.blogsimgs[0]?.url}><img src={row?.blogsimgs.length === 0 ? NOImagehere : row?.blogsimgs[0]?.url } style={{ width:50,height:50 }}  /></a> : 'No Image Available' }</div>,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => moment(row?.createdAt).format("DD MMM YYYY hh:mm a"),
            sortable: true,
            wrap:true
        },
        {
            name: 'Published',
            selector: row => <div className='text-center'> {row?.isPublished
                === true ? (<b>true</b>): (
                ""
              )} <div> {row?.isPublished
                === false ? (<b>false</b>): (
                ""
              )}</div>
              </div>,
              
            sortable: true,
            wrap:true
        },
        {
            name:'Action',
            selector: row => <div ><button className='btn btn-sm btn-outline-success' onClick={ () => { navigate("/view-blog",{state:row}) } }>View</button>&nbsp;&nbsp;<button className='btn btn-sm btn-outline-info button' onClick={ () => { navigate("/editblog",{state:row}) } }>Edit</button>&nbsp;</div>
  
        }

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                color: 'red',
                fontSize: '16px'
            },
        },
        cells: {
            style: {
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '10px',
                fontSize: '16px'
            },
        },
    };

    console.log("data",data)
    return (
        <div>
            <MainSideBarContainer active='blog' />
            <div>
                <div className="content-wrapper">

                    {/* <ContentWrapper headContent='Users' myCompo="" /> */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Blogs</h1>
                                </div>
                                <div className='col-sm-6'>
                                    <button className='btn btn-dark float-right mr-5 mt-1 button' onClick={() => navigate('/addblog') }>Add Blog</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="container-fluid pt-4 px-4">
                                <div className=''>
                                <input type="text" placeholder= "Search" onChange={(e)=>{filterData(e.target.value)}} />
                                    <div className="row bg-light rounded justify-content-center p-3 mx-0">
                                    {loading ? (
                                         <div className="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                       </div>
                                         ) : (
                                        <div className='col-md-12 lm-6 table-bordered' style={{paddingLeft:'0px',paddingRight:'0px',}}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                sortIcon={sortIcon}
                                                pagination
                                                responsive
                                               // customStyles={customStyles}
                                               // theme="solarized"
                                            />
                                        </div> )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
