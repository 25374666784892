import React, { useEffect, useState } from 'react'
import MainSideBarContainer from './MainSideBarContainer'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from 'axios';
import dayjs from 'dayjs';

import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";


const sortIcon = <ArrowDownward />;

function AppliedCouponList() {
    const navigate = useNavigate()
   // const [data, setData] = useState([])
    
    const [appliedCoupon, setappliedCoupon] = useState([]);
    const [loading, setLoading] = useState(false);
    //const [modalIsOpen, setIsOpen] = React.useState(false);
    const [filteredData, setFilteredData] = useState()

    const [show, setShow] = useState(false);
    const [filterCode,setFilterCode] = useState('')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handle = (appliedCoupon) => {
        handleShow()
        setFilterCode(appliedCoupon)
    }


    const filterData = (char) => {
      
        let f = filteredData?.filter( item => item?.couponCode?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) )
        setappliedCoupon(f)
        console.log("MyData-----46", f)
     
      }



    const getcoupondata = async () => {
      
       setLoading(true);
      await fetch(process.env.REACT_APP_BASE_URL + "/appliedCoupon/getAllAppliedCoupon").then(response => response.json()).then((res) => {
           setLoading(false);    
           console.log(res.data);
                setappliedCoupon(res.data);
              //  console.log("ressssss 74", res)
                
                setFilteredData(res.data);

                return res
            })
            .catch((error) => {
                
               setLoading(false);
                console.error('Error:', error);
                 setLoading(false)
            })
    }



    useEffect(() => {
      //  getdata()
        getcoupondata()
    }, [])


   


    const columns = [
        {
            name: 'No.',
            selector: (row, index) => index + 1,
            width:'8%'
        },
        {
            name: 'Coupon Id',
            selector: row => row?.couponID,
            sortable: true,
            wrap: true
        },
        {
            name: 'Coupon Code',
            selector: row => row?.couponCode,
            sortable: true,
            wrap: true
        },
       
        {
            name: 'Mobile ',
            selector: row =>row?.mobile,
            sortable: true,
            wrap: true
        },
        {
            name: 'Created On',
            selector: row => moment(row?.createdAt).format("DD MMM YYYY"),
            sortable: true,
            wrap: true
        },
        // {
        //     name: 'Action',
        //     selector: row => <div>
        //         <button className='btn btn-sm btn-outline-dark mt-1 ml-1' onClick={ () => handle(row?.couponCode)}  >
        //             View
        //         </button>
        //     </div >,
        //     sortable: true,
        // },
        
    ];



    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                color: 'red',
                fontSize: '16px'
            },
        },
        cells: {
            style: {
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '10px',
                fontSize: '16px'
            },
        },
    };


    const downloadExcel = (customHeadings) => {
        let dataSet = [];
        console.log("dataset", dataSet)
        dataSet = customHeadings;
        const worksheet = XLSX.utils.json_to_sheet(dataSet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "CouponReport.xlsx");
    };

    const CustomExcel = () => {

        const customHeadings = appliedCoupon.length > 0 && appliedCoupon.map(item => ({

            
            "Coupon ID":item.couponID ? item.couponID : '-',
            "Coupon Code":item.couponCode ? item.couponCode : '-', 
            "Mobile": item.mobile ? item.mobile : '-',
            // "Course Name":item.course_name ? item.course_name : '-',
            "Created On": item.createdAt ? moment(item.createdAt).format("DD MMM YYYY hh:mm a") : '-',
            

            
        }))
        console.log("Customm Excel", customHeadings)
        downloadExcel(customHeadings)
    };


    return (
        <div>
            <MainSideBarContainer active='appliedcouponlist' />
            <div>
                <div className="content-wrapper">

                    {/* <ContentWrapper headContent='Users' myCompo="" /> */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Applied Coupon List</h1>
                                </div>
                                
          
                                 <div className='col-sm-6'>
                                    <button className='btn btn-dark float-right mr-5 mt-1 button' onClick={CustomExcel}>Export Data</button>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="container-fluid pt-4 px-4">
                                {/*  */}
                                <input type="text" placeholder= "Search" onChange={(e)=>{filterData(e.target.value)}} />
                                <div className=''>
                                    <div className="row bg-light rounded justify-content-center p-3 mx-0">
                                        {loading ? (
                                            <div className="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <div className='col-md-12 lm-6 table-bordered' style={{paddingLeft:'0px',paddingRight:'0px',}}>
                                                <DataTable
                                                    columns={columns}
                                                    data={appliedCoupon}
                                                    sortIcon={sortIcon}
                                                    pagination
                                                    responsive
                                                   // customStyles={customStyles}
                                                
                                                />
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default AppliedCouponList;