import React, { useState, useEffect } from "react";
import MainSideBarContainer from "./MainSideBarContainer";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultImage from "../img/Profile Admin.png";
import uploadImg from "../img/upload.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Compressor from "compressorjs";
import S3 from "react-aws-s3/dist/react-aws-s3";
import moment from "moment";
import axios from "axios";

window.Buffer = window.Buffer || require("buffer").Buffer;

function Viewcourse() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [courseName, setcourseName] = useState("");
  const [courseDes, setcourseDes] = useState("");
  const [courseType, setcourseType] = useState("");
  const [basePrice, setbasePrice] = useState("");
  const [offerPrice, setofferPrice] = useState("");
  const [courseDuration, setcourseDuration] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [lastRegistrationDate, setlastRegistrationDate] = useState("");
  const [courseTag, setcourseTag] = useState("");
  const [courseImgURL, setcourseImgURL] = useState([]);
  const [loader, setLoader] = useState(false);
  const date = new Date();
  //validation state data
  const [error, setError] = useState(false);
  const [courseNameError, setcourseNameError] = useState(false);
  const [courseDesError, setcourseDesError] = useState(false);
  const [courseTypeError, setcourseTypeError] = useState(false);
  const [basePriceError, setbasePriceError] = useState(false);
  const [offerPriceError, setofferPriceError] = useState(false);
  const [courseDurationError, setcourseDurationError] = useState(false);
  const [startDateError, setstartDateError] = useState(false);
  const [endDateError, setendDateError] = useState(false);
  const [lastRegistrationDateError, setlastRegistrationDateError] =
    useState(false);
  const [courseTagError, setcourseTagError] = useState(false);
  const [courseImgURLError, setcourseImgURLError] = useState(false);

  const getdata = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();

    urlencoded.append("courseId", location.state.courseId);

    let requestoptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    await fetch(
      process.env.REACT_APP_BASE_URL+"/course/viewsinglecourses",
      requestoptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("-------view course API response----------", res);
        setData(res);
        setcourseName(res.courseName);
        setcourseDes(res.courseDes);
        setcourseType(res.courseType);
        setbasePrice(res.basePrice);
        setofferPrice(res.offerPrice);
        setstartDate(res.startDate);
        setendDate(res.endDate);
        setcourseTag(res.courseTag);
        setcourseDuration(res.courseDuration);
        setcourseImgURL(res.courseImgURL);

        // navigate("/blog")
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    console.log("Props", location.state);
    getdata();
  }, []);
  const handleUpdate = async () => {
    if (formvalidate()) {
      console.log("Form is Valid");

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let formData = new FormData();

      formData.append("courseId", location.state.courseId);
      formData.append("courseName", courseName);
      formData.append("courseDes", courseDes);
      formData.append("endDate", moment(date).format("YYYY-MM-DD"));
      formData.append("startDate", moment(date).format("YYYY-MM-DD"));
      formData.append("courseType", courseType);
      formData.append("basePrice", basePrice);
      formData.append("offerPrice", offerPrice);
      formData.append("courseDuration", courseDuration);
      formData.append("lastRegistrationDate", lastRegistrationDate);
      formData.append("courseTag", courseTag);
      // formData.append("courseId",location.state.courseId)
      formData.append("courseImgURL", courseImgURL);

      await axios
        .put(
          process.env.REACT_APP_BASE_URL+"/course/updatecourses",
          formData
        )
        .then((response) => response.json())
        .then((res) => {
          console.log("-------Edit course API response----------", res);
          navigate("/Courselist");
          return res;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const formvalidate = () => {
    let IsformValid = true;

    if (!courseName) {
      IsformValid = false;
      setcourseNameError(true);
    } else if (courseName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (!courseNameError) {
      IsformValid = false;
      setcourseNameError(true);
    }
    if (!startDate) {
      IsformValid = false;
      setstartDateError(true);
    }
    if (!endDate) {
      IsformValid = false;
      setendDateError(true);
    }
    if (!courseDes) {
      IsformValid = false;
      setcourseDesError(true);
    }
    if (!courseType) {
      IsformValid = false;
      setcourseTypeError(true);
    }
    if (!basePrice) {
      IsformValid = false;
      setbasePriceError(true);
    }
    if (!offerPrice) {
      IsformValid = false;
      setofferPriceError(true);
    }
    if (!courseDuration) {
      IsformValid = false;
      setcourseDurationError(true);
    }
    if (!lastRegistrationDate) {
      IsformValid = false;
      setlastRegistrationDateError(true);
    }
    if (!courseTag) {
      IsformValid = false;
      setcourseTagError(true);
    }
    if (!courseImgURL) {
      IsformValid = false;
      setcourseImgURLError(true);
    }

    return IsformValid;
  };

  const handleDelete = async () => {
    // console.log("Title", title)
    // console.log("description", description)
    // console.log("bbbblogimg", blogimg)
    // console.log("Date",moment(date).format("DD MMM YYYY"))

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();

    urlencoded.append("courseId", location.state.courseId);

    let requestoptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BASE_URL+"/course/deletecourses",
      requestoptions
    )
      .then((response) => response.json())
      .then((res) => {
        console.log("-------delete courseAPI response----------", res);
        navigate("/Courselist");
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // const handleSubmit = async () => {

  //     let myHeaders = new Headers()
  //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

  //     let urlencoded = new URLSearchParams();

  //     urlencoded.append("courseName", courseName);
  //     urlencoded.append("courseDes", courseDes);
  //     urlencoded.append("courseType", courseType);
  //     urlencoded.append("basePrice", basePrice);
  //     urlencoded.append("offerPrice",offerPrice);
  //     urlencoded.append("courseDuration",courseDuration);
  //     urlencoded.append("startDate", startDate);
  //     urlencoded.append(" endDate",endDate);
  //     urlencoded.append("lastRegistrationDate",lastRegistrationDate);
  //     urlencoded.append("courseTag",courseTag);
  //     urlencoded.append("courseImgURL",courseImgURL);

  //     let requestoptions = {
  //         method: 'POST',
  //         headers: myHeaders,
  //         body: urlencoded,
  //         redirect: 'follow'
  //     }

  //     await axios.post(process.env.REACT_APP_BASE_URL+"/course/addcourses",requestoptions).then(response => response.json()).then((res) => {
  //             console.log("-------Add course API response----------",res.data)
  //             navigate("/Courselist")
  //             return res;
  //     })
  //         .catch((error) => {
  //             console.error('Error:', error);
  //         });
  // }

  const handleChange = async (e) => {
    if (e.target.files.length) {
      console.log("ENV", process.env.REACT_APP_BUCKET_NAME);
      const img = URL.createObjectURL(e.target.files[0]);
      uploadImageToS3(e, "courseImgURL");
    }
  };

  const uploadImageToS3 = (event, type) => {
    setLoader(true);
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + type,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    let fileInput = false;
    const image = event.target.files[0];
    console.log("image data before compression-------------- ", image);
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.7,
        success: (compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = getTimeStamp + "_" + "bawd";
          console.log("------- filename image upload --------", newFileName);

          const ReactS3Client = new S3(config);

          ReactS3Client.uploadFile(compressedResult, newFileName)
            .then((res) => {
              courseImgURL.push(res.location);
              setcourseImgURL(courseImgURL);
              console.log("uploded image", res);
              console.log("image uploaded successfully!!!!");
              setLoader(false);
            })
            .catch((err) => {
              console.error(err);
            });
        },
      });
      console.log("--------------- Upload image -----------");
    }
  };

  return (
    <div>
      <MainSideBarContainer />
      <div>
        <div className="content-wrapper">
          {/* <ContentWrapper headContent='Users' myCompo="" /> */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <button className="btn btn-dark button" onClick={() => navigate(-1)}>
                    Back
                  </button>
                </div>
                <div className="col-sm-6">
                  <h1 className="m-0"></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div
                className="container-fluid pt-4 px-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  className="shadow-lg"
                  style={{
                    margin: 10,
                    width: "80%",
                    borderRadius: 10,
                    // justifyContent: "center",
                    // textAlign: "center",
                  }}
                >
                  <h1
                    className="mt-3"
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    EDIT COURSE
                  </h1>
                  <Card.Body>
                    <div>
                      <div>
                        {/* <div className='blog-Image mt-3' style={{ position: 'relative' }}>
                                                    <img className='image-fluid' style={{ width: 200, height: 200 }} src={blogimg.length === 0 ? DefaultImage : blogimg[0]} />
                                                    <input accept='image/*' type="file" onChange={handleChange} style={{ marginLeft: 10 }} />
                                                   
                                                </div> */}
                        <div className="coruse-name mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Course Name
                          </label>
                          <input
                            className="form-control"
                            placeholder="courseName"
                            value={courseName}
                            error={courseName.match(/[0-9+@#$&%!~]/)}
                            onChange={(e) => setcourseName(e.target.value)}
                          />
                        </div>
                        {courseNameError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter course Name
                          </span>
                        ) : null}
                        <div className="course-description mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Course Description
                          </label>
                          <input
                            className="form-control"
                            placeholder="courseDes"
                            value={courseDes}
                            onChange={(e) => setcourseDes(e.target.value)}
                          />
                        </div>
                        {courseDesError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter course Description
                          </span>
                        ) : null}
                        <div className="coruse-coursetype mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Course Type
                          </label>
                          <input
                            className="form-control"
                            placeholder="courseType"
                            value={courseType}
                            onChange={(e) => setcourseType(e.target.value)}
                          />
                        </div>
                        {courseTypeError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter coursetype
                          </span>
                        ) : null}
                        <div className="coruse-baseprice mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Course BasePrice
                          </label>
                          <input
                            className="form-control"
                            placeholder="basePrice"
                            value={basePrice}
                            onChange={(e) => setbasePrice(e.target.value)}
                          />
                        </div>
                        {basePriceError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter value
                          </span>
                        ) : null}
                        <div className="coruse-offerprice mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Course OfferPrice
                          </label>
                          <input
                            className="form-control"
                            placeholder="offerPrice"
                            value={offerPrice}
                            onChange={(e) => setofferPrice(e.target.value)}
                          />
                        </div>
                        {offerPriceError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter offerPrice
                          </span>
                        ) : null}
                        <div className="coruse-startDate mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Course StartDate
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="startDate"
                            value={startDate}
                            onChange={(e) => setstartDate(e.target.value)}
                          />
                        </div>
                        {startDateError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter startDate
                          </span>
                        ) : null}
                        <div className="endDate mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Course EndDate
                          </label>
                          <input
                           type="date"
                            className="form-control"
                            placeholder="endDate"
                            value={endDate}
                            onChange={(e) => setendDate(e.target.value)}
                          />
                        </div>
                        {endDateError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter endDate
                          </span>
                        ) : null}
                        <div className="lastRegistrationDate mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Last RegistrationDate
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="lastRegistrationDate"
                            value={data.lastRegistrationDate}
                          />
                        </div>
                        {lastRegistrationDateError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter lastRegistrationDate
                          </span>
                        ) : null}
                        <div className="courseDuration mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Course Duaration
                          </label>
                          <input
                            className="form-control"
                            placeholder="courseDuration"
                            value={courseDes}
                            onChange={(e) => setcourseDuration(e.target.value)}
                          />
                        </div>
                        {courseDurationError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter courseDuration
                          </span>
                        ) : null}
                        <div className="coruse-tag mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Course Tag
                          </label>
                          <input
                            className="form-control"
                            placeholder="coursetag"
                            value={courseTag}
                            onChange={(e) => setcourseTag(e.target.value)}
                          />
                        </div>
                        {courseTagError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter courseTagError
                          </span>
                        ) : null}
                        <div
                          className="course-Image mt-3"
                          style={{ position: "relative" }}
                        >
                          <img
                            className="image-fluid"
                            style={{ width: 200, height: 200 }}
                          />
                          <input
                            accept="image/*"
                            type="file"
                            onChange={handleChange}
                            style={{ marginLeft: 10 }}
                          />
                          {/* <div style={{ position:'absolute',backgroundColor:'transparent',height:'100%',width:'100%',zIndex:2 }}>
                                                       <img src={uploadImg} style={{ width:100,height:100}} />
                                                    </div> */}
                        </div>
                        {courseImgURLError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter courseImgURL
                          </span>
                        ) : null}
                      </div>
                      <button
                        className="btn btn-primary mt-2 button"
                        style={{
                          fontSize: 20,
                          borderBottomRightRadius: 5,
                          borderBottomLeftRadius: 5,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-primary ml-2 mt-2 button"
                        style={{
                          fontSize: 20,
                          borderBottomRightRadius: 5,
                          borderBottomLeftRadius: 5,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete();
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Viewcourse;
