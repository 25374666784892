import React, { useEffect } from 'react'
// import $ from 'jquery'
// import Sidebar from './Sidebar';
import Nav from './Nav';
import Footer from './Footer';
import MainSideBarContainer from './MainSideBarContainer';
import ContentWrapper from './ContentWrapper';
import ControlSideBar from './ControlSideBar';
import User from './User'
import { useNavigate } from 'react-router-dom';




export default function Dashboard() {

    const navigate = useNavigate()
    console.log(localStorage.getItem("MahaReraUser"))
    useEffect( () => {
        if(localStorage.getItem("MahaReraUser") === null){
            navigate("/login")
        }
    },[] )
    
    return (
        <div>
             
                
                    <MainSideBarContainer active='dashboard' />
                    <ContentWrapper headContent='Dashboard' myCompo="" />
                    <ControlSideBar />
            
        </div>
    )
}
