import React, { useEffect, useState } from 'react'
import Nav from './Nav'
import MainSideBarContainer from './MainSideBarContainer'
import DataTable, { Media, createTheme } from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';

const sortIcon = <ArrowDownward />;
export default function Batchlist() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    // const [filteredData, setFilteredData] = useState()
    const navigate = useNavigate()
    useEffect(() => {
        getdata()
    }, [])

    const getdata = async () => {
        setLoading(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/batch/getAllBatch').then(response => response.json()).then(res => {
            setLoading(false);
            setData(res.row_id[0])
            // setFilteredData(res);
            console.log("Batchlist", res)
            return res
        })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
            });
    }

    // const filterData = (char) => {
    //     // setLoader(true);
    //     // console.log(char)
    //     // console.log("Charracter Array", filteredData)
    //     let f = filteredData?.filter(item => item?.courseName?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
    //       item?.basePrice?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
    //       item?.offerPrice?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
    //       dayjs(item?.startDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
    //        dayjs(item?.endDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase())||
    //        dayjs(item?.lastRegistrationDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase())
    //      // item?.City?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
    //      // item?.district?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) 
    //     //   item?.gramsevakhName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
    //     //   item?.serverNo?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) 
    //       )
    //       setData(f)
    //     console.log("MyData", f)
    //     // setLoader(false);
    //   }


    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
            width: '7%',

        },
        {
            name: 'Batch Name',
            selector: row => row?.name,
            sortable: true,
            wrap: true
        },
        {
            name: 'Discription',
            selector: row => row?.description,
            sortable: true,
            wrap: true
        },
        {
            name: 'Shift',
            selector: row => row?.shift,
            sortable: true,
            wrap: true
        },
        {
            name: 'Start Date',
            selector: row => moment(row?.start_date).format("DD MMM YYYY"),
            sortable: true,
            wrap: true
        },
        {
            name: 'End Date',
            selector: row =>moment(row?.end_date).format("DD MMM YYYY"),
            sortable: true,
            wrap: true
        },
        {
            name: 'Start Time',
            selector: row => row.start_time,
            sortable: true,
            wrap: true
        },
        {
            name: 'End Time',
            selector: row => row.end_time,
            sortable: true,
            wrap: true
        },

        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            wrap: true
        },
   
        {
            name:'Action',
            selector: row => <div ><button className=' btn btn-sm btn-outline-primary mt-1 button' onClick={ () => { navigate("/updatebatch",{state:row}) } }>Edit</button></div>,
            sortable: true,
            wrap:true
        }


    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height

            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                color: 'red',
                fontSize: '16px'
            },
        },
        cells: {
            style: {
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '10px',
                fontSize: '16px',
                width: '80px'
            },
        },
    };



    return (
        <div>
            {/* NavBar */}

            <MainSideBarContainer active='batch' />
            <div>
                <div className="content-wrapper">

                    {/* <ContentWrapper headContent='Users' myCompo="" /> */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Batch List</h1>
                                </div>
                                <div className='col-sm-6'>
                                    <button className='btn btn-dark float-right mr-5 mt-1 button' onClick={() => navigate('/addbatch')}>Add Batch</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="container-fluid pt-4 px-4">
                                <div className=''>

                                    <div className="row bg-light rounded justify-content-center p-3 mx-0">
                                        {loading ? (
                                            <div className="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <div className='col-md-12 lm-6 table-bordered' style={{paddingLeft:'0px',paddingRight:'0px',}}>
                                                {
                                                    data && <DataTable
                                                        columns={columns}
                                                        data={data}
                                                        sortIcon={sortIcon}
                                                        pagination
                                                        responsive
                                                        //customStyles={customStyles}
                                                    // theme="solarized"
                                                    />
                                                }
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
