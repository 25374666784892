import React from 'react';
import DataTable, { Media, createTheme } from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';



const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
createTheme('solarized', {
    text: {
        primary: '#268bd2',
        secondary: '#2aa198',
    },
    background: {
        default: '#002b36',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#073642',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

const columns = [
    {
        name: 'Title',
        selector: row => row.title,
        sortable: true,

    },
    {
        name: 'Year',
        selector: row => row.year,
        sortable: true,
    },
    {
        name: 'Title',
        selector: row => row.title,
        sortable: true,
    },
    {
        name: 'Yr',
        selector: row => row.yr,
        sortable: true,
    },

];

const data = [

    {
        id: 1,
        title: 'Beetlejuice',
        year: '1988',
        yr: '1984'
    },
    {
        id: 2,
        title: 'Ghostbusters',
        year: '1984',
        yr: '1988'
    },
    {
        id: 3,
        title: 'Beetlejuice',
        year: '1988',
        yr: '1984',
    },
    {
        id: 4,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 5,
        title: 'Beetlejuice',
        year: '1988',
    },
    {
        id: 6,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 7,
        title: 'Beetlejuice',
        year: '1988',
    },
    {
        id: 8,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 9,
        title: 'Beetlejuice',
        year: '1988',
    },
    {
        id: 10,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 11,
        title: 'Beetlejuice',
        year: '1988',
    },
    {
        id: 12,
        title: 'Ghostbusters',
        year: '1984',
    },
]
const customStyles = {
    table: {
        style: {
            color: 'orange',
            backgroundColor: 'black',
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            color: 'red',
            fontSize: '16px'
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const conditionalRowStyles = [
    {
        when: row => row.title == 'Beetlejuice',
        style: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        }
    },
    {
        when: row => row.title == 'Ghostbusters',
        style: {
            backgroundColor: 'lightgreen',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        }
    },

];

export default function User() {

    return (
        <div>
            <div className="content">
                {/* <!-- Blank Start --> */}
                <div className="container-fluid pt-4 px-4">
                    <div className="row vh-100 bg-light rounded justify-content-center p-3 mx-0">
                        <div className="col-md-6 text-center" >
                            <DataTable
                                columns={columns}
                                data={data}
                                sortIcon={sortIcon}
                                conditionalRowStyles={conditionalRowStyles}
                                pagination
                                responsive
                                dense
                            // theme="solarized"
                            />
                        </div>
                    </div>
                </div>
                {/* <!-- Blank End --> */}
            </div>
        </div>
    )
}
