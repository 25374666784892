import React, { useState } from "react";
import MainSideBarContainer from "./MainSideBarContainer";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../img/Profile Admin.png";
import uploadImg from "../img/upload.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Compressor from "compressorjs";
import S3 from "react-aws-s3/dist/react-aws-s3";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";

window.Buffer = window.Buffer || require("buffer").Buffer;

function AddCourse() {
  const navigate = useNavigate();
  const [courseName, setcourseName] = useState("");
  const [courseDes, setcourseDes] = useState("");
  const [courseType, setcourseType] = useState("");
  const [basePrice, setbasePrice] = useState("");
  const [offerPrice, setofferPrice] = useState("");
  const [courseDuration, setcourseDuration] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [lastRegistrationDate, setlastRegistrationDate] = useState("");
  const [courseTag, setcourseTag] = useState("");
  const [courseImgURL, setcourseImgURL] = useState([]);
  const [loader, setLoader] = useState(false);
  const date = new Date();
  //validation state data
  const [error, setError] = useState(false);
  const [courseNameError, setcourseNameError] = useState(false);
  const [courseDesError, setcourseDesError] = useState(false);
  const [courseTypeError, setcourseTypeError] = useState(false);
  const [basePriceError, setbasePriceError] = useState(false);
  const [offerPriceError, setofferPriceError] = useState(false);
  const [courseDurationError, setcourseDurationError] = useState(false);
  const [startDateError, setstartDateError] = useState(false);
  const [endDateError, setendDateError] = useState(false);
  const [lastRegistrationDateError, setlastRegistrationDateError] = useState(false);
  const [courseTagError, setcourseTagError] = useState(false);
  const [courseImgURLError, setcourseImgURLError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (formvalidate()) {
      console.log("Form is Valid");

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let formData = new URLSearchParams();

      formData.append("courseName", courseName);
      formData.append("courseDes", courseDes);
      formData.append("courseType", courseType);
      formData.append("basePrice", basePrice);
      formData.append("offerPrice", offerPrice);
      formData.append("courseDuration", courseDuration);
      formData.append("startDate", startDate);
      formData.append(" endDate", endDate);
      formData.append("lastRegistrationDate", lastRegistrationDate);
      formData.append("courseTag", courseTag);
      formData.append("courseImgURL", courseImgURL);

      await axios
        .post(process.env.REACT_APP_BASE_URL+"/course/addcourses", formData)
        .then((res) => {
          setLoading(false);
          console.log("-------Add course API response----------", res.data);
          navigate("/Courselist");
          return res;
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    }
  };

  const formvalidate = () => {
    let IsformValid = true;

    if (!courseName) {
      IsformValid = false;
      setcourseNameError(true);
    } else if (courseName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (!courseNameError) {
      IsformValid = false;
      setcourseNameError(true);
    }
    if (!startDate) {
      IsformValid = false;
      setstartDateError(true);
    }
    if (!endDate) {
      IsformValid = false;
      setendDateError(true);
    }
    if (!courseDes) {
      IsformValid = false;
      setcourseDesError(true);
    }
    if (!courseType) {
      IsformValid = false;
      setcourseTypeError(true);
    }
    if (!basePrice) {
      IsformValid = false;
      setbasePriceError(true);
    }
    if (!offerPrice) {
      IsformValid = false;
      setofferPriceError(true);
    }
    if (!courseDuration) {
      IsformValid = false;
      setcourseDurationError(true);
    }
    if (!lastRegistrationDate) {
      IsformValid = false;
      setlastRegistrationDateError(true);
    }
    if (!courseTag) {
      IsformValid = false;
      setcourseTagError(true);
    }
    if (!courseImgURL) {
      IsformValid = false;
      setcourseImgURLError(true);
    }

    return IsformValid;
  };

  const handleChange = async (e) => {
    if (e.target.files.length) {
      console.log("ENV", process.env.REACT_APP_BUCKET_NAME);
      const img = URL.createObjectURL(e.target.files[0]);
      uploadImageToS3(e, "courseImgURL");
    }
  };

  const uploadImageToS3 = (event, type) => {
    setLoader(true);
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + type,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    let fileInput = false;
    const image = event.target.files[0];
    console.log("image data before compression-------------- ", image);
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.7,
        success: (compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = getTimeStamp + "_" + "bawd";
          console.log("------- filename image upload --------", newFileName);

          const ReactS3Client = new S3(config);

          ReactS3Client.uploadFile(compressedResult, newFileName)
            .then((res) => {
              courseImgURL.push(res.location);
              setcourseImgURL(courseImgURL);
              console.log("uploded image", res);
              console.log("image uploaded successfully!!!!");
              setLoader(false);
            })
            .catch((err) => {
              console.error(err);
            });
        },
      });
      console.log("--------------- Upload image -----------");
    }
  };

  return (
    <div>
      <MainSideBarContainer />
      <div>
        {loading ? (
          <div className="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="content-wrapper">
            {/* <ContentWrapper headContent='Users' myCompo="" /> */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <button
                      className="btn btn-dark button"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <h1 className="m-0"></h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="container-fluid">
                <div
                  className="container-fluid pt-4 px-4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Card
                    className="shadow-lg"
                    style={{
                      margin: 10,
                      width: "80%",
                      borderRadius: 10,
                      // justifyContent: "center",
                      // textAlign: "center",
                    }}
                  >
                    <h1
                      className="mt-3"
                      style={{ justifyContent: "center", textAlign: "center",fontSize:"30px",paddingTop:"10px",paddingBottom:"10px" }}
                    >
                      ADD COURSE
                    </h1>
                    <Card.Body>
                      <div>
                        <div>
                          {/* <div className='blog-Image mt-3' style={{ position: 'relative' }}>
                                                    <img className='image-fluid' style={{ width: 200, height: 200 }} src={blogimg.length === 0 ? DefaultImage : blogimg[0]} />
                                                    <input accept='image/*' type="file" onChange={handleChange} style={{ marginLeft: 10 }} />
                                                </div> */}

                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-6"> 
                              <div className="coruse-name mt-3">
                                <label style={{ fontSize: 18, letterSpacing: 1 }}>
                                  Course Name<span style={{ color: "red", fontSize: 15 }}>*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="courseName"
                                  error={courseName.match(/[0-9+@#$&%!~]/)}
                                  onChange={(e) => setcourseName(e.target.value)}
                                  required
                                />
                              </div>
                              {courseNameError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter course Name
                                </span>
                              ) : null}

                              <div className="coruse-coursetype mt-3">
                                <label style={{ fontSize: 18, letterSpacing: 1 }}>
                                  Course Type<span style={{ color: "red", fontSize: 15 }}>*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="courseType"
                                  onChange={(e) => setcourseType(e.target.value)}
                                  required
                                />
                              </div>
                              {courseTypeError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter coursetype
                                </span>
                              ) : null}


                            </div>
                              
                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="course-description mt-3">
                                <label style={{ fontSize: 18, letterSpacing: 1 }}>
                                  Course Description<span style={{ color: "red", fontSize: 15 }}>*</span>
                                </label>
                                <textarea
                                  className="form-control"
                                  style={{marginLeft:'12px'}}
                                  placeholder="write course descriptipn here..."
                                  error={courseDes.match(/[0-9+@#$&%!~]/)}
                                  onChange={(e) => setcourseDes(e.target.value)}
                                  rows="5" cols="50"
                                  required
                                />
                              </div>
                                {courseDesError ? (
                                  <span style={{ color: "red", fontSize: 15 }}>
                                    Please Enter course Description
                                  </span>
                                ) : null}
                            </div>
                            
                          </div>
                    
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-6"> 
                              <div className="coruse-baseprice mt-3">
                                <label style={{ fontSize: 18, letterSpacing: 1 }}>
                                  Course BasePrice<span style={{ color: "red", fontSize: 15 }}>*</span>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="basePrice"
                                  onChange={(e) => setbasePrice(e.target.value)}
                                  required
                                />
                              </div>
                              {basePriceError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter value
                                </span>
                              ) : null}
                            </div>
                            <div className="col-lg-6 col-md-6 col-6"> 
                              <div className="coruse-offerprice mt-3">
                                <label style={{ fontSize: 18, letterSpacing: 1 }}>
                                  Course OfferPrice<span style={{ color: "red", fontSize: 15 }}>*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="offerPrice"
                                  onChange={(e) => setofferPrice(e.target.value)}
                                  required
                                />
                              </div>
                              {offerPriceError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter offer Price
                                </span>
                              ) : null}
                            </div>
                          </div>                         

                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="coruse-startDate mt-3">
                                <label style={{ fontSize: 18, letterSpacing: 1 }}>
                                  Course Start Date<span style={{ color: "red", fontSize: 15 }}>*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="startDate"
                                  onChange={(e) => setstartDate(e.target.value)}
                                  required
                                />
                              </div>
                              {startDateError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter start Date
                                </span>
                              ) : null}
                            </div>
                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="endDate mt-3">
                                <label style={{ fontSize: 18, letterSpacing: 1 }}>
                                  Course End Date<span style={{ color: "red", fontSize: 15 }}>*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="endDate"
                                  onChange={(e) => setendDate(e.target.value)}
                                  required
                                />
                              </div>
                              {endDateError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter end Date
                                </span>
                              ) : null}
                            </div>
                          </div>
                         
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="lastRegistrationDate mt-3">
                                  <label style={{ fontSize: 18, letterSpacing: 1 }}>
                                    Last Registration Date<span style={{ color: "red", fontSize: 15 }}>*</span>
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="lastRegistrationDate"
                                    onChange={(e) =>
                                      setlastRegistrationDate(e.target.value)
                                    }
                                    required
                                  />
                                </div>
                                {lastRegistrationDateError ? (
                                  <span style={{ color: "red", fontSize: 15 }}>
                                    Please Enter lastRegistration Date
                                  </span>
                                ) : null}
                            </div>
                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="courseDuration mt-3">
                                <label style={{ fontSize: 18, letterSpacing: 1 }}>
                                  Course Duaration(Hrs)<span style={{ color: "red", fontSize: 15 }}>*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="courseDuration"
                                  onChange={(e) =>
                                    setcourseDuration(e.target.value)
                                  }
                                  required
                                />
                              </div>
                              {courseDurationError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter course Duration
                                </span>
                              ) : null}
                            </div>
                          </div>
                       
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="coruse-tag mt-3">
                                <label style={{ fontSize: 18, letterSpacing: 1 }}>
                                  Course Tag<span style={{ color: "red", fontSize: 15 }}>*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="coursetag"
                                  onChange={(e) => setcourseTag(e.target.value)}
                                  required
                                />
                              </div>
                              {courseTagError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter course Tag Error
                                </span>
                              ) : null}
                            </div>
                            <div className="col-lg-6 col-md-6 col-6">
                              <div
                                className="course-Image mt-3"
                                style={{ position: "relative" }}
                              >
                                <img
                                  className="image-fluid"
                                  style={{ width: 100, height: 100 }}
                                  src={
                                    courseImgURL.length === 0
                                      ? DefaultImage
                                      : courseImgURL[0]
                                  }
                                />
                                <input
                                  accept="image/*"
                                  type="file"
                                  onChange={handleChange}
                                  style={{ marginLeft: 10 }}
                                  required
                                />
                                {/* <div style={{ position:'absolute',backgroundColor:'transparent',height:'100%',width:'100%',zIndex:2 }}>
                                                          <img src={uploadImg} style={{ width:100,height:100}} />
                                                        </div> */}
                              </div>
                              {courseImgURLError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter course ImgURL
                                </span>
                              ) : null}
                            </div>
                          </div>   
                          
                        </div>
                      </div>
                    </Card.Body>
                    <button
                      className="btn btn-primary button"
                      style={{
                        display: 'block',
                        margin: '0 auto',
                        marginTop: '20px',
                        marginBottom: '50px',
                        fontSize: 20,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                        width:"20%"
                      }}
                      onClick={(e) => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddCourse;
