import React, { useState } from "react";
import MainSideBarContainer from "./MainSideBarContainer";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../img/Profile Admin.png";
import uploadImg from "../img/upload.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Compressor from "compressorjs";
import S3 from "react-aws-s3/dist/react-aws-s3";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";

import { MdMargin } from "react-icons/md";
import Dropdown from 'react-dropdown';





window.Buffer = window.Buffer || require("buffer").Buffer;

function AddBatch() {
  const navigate = useNavigate();
  const [batchName, setBatchName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState("");
  const [status, setStatus] = useState("");
  const [shift, setShift] = useState("");
  const [starttime, setStarttime] = useState("");
  const [endtime, setEndtime] = useState("");
  const [meridiem, setMeridiem] = useState("");
  const [meri, setMeri] = useState("");
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);

  const [error, setError] = useState(false);
  const [batchNameError, setbatchNameError] = useState(false);
  const [descriptionError, setdescriptionError] = useState(false);
  const [startDateError, setstartDateError] = useState(false);
  const [endDateError, setendDateError] = useState(false);
  const [statusError, setstatusError] = useState(false);
  const [shiftError, setshiftError] = useState(false);
  const [starttimeError, setstarttimeError] = useState(false);
  const [endtimeError, setendtimeError] = useState(false);
  const [meridiemError, setMeridiemError] = useState(false);
  const [meriError, setMeriError] = useState(false);

  const handleChange = (selectedOption) => {
    setSelected(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };



  const handleRadioChange = (e) => {
    setStatus(e.target.value);
  };

  const [loader, setLoader] = useState(false);
  const date = new Date();
  const shiftDropdown = [
    {
      label: "Morning",
      value: "morning",
    },
    {
      label: "Evening",
      value: "evening",
    },
  ];
  const shiftTime = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
    {
      label: "6",
      value: 6,
    },
    {
      label: "7",
      value: 7,
    },
    {
      label: "8",
      value: 8,
    },
    {
      label: "9",
      value: 9,
    },
    {
      label: "10",
      value: 10
    },
    {
      label: "11",
      value: 11,
    },
    {
      label: "12",
      value: 12,
    },

  ];

  const shiftDropTime = [
    {
      label: "AM",
      value: "am",
    },
    {
      label: "PM",
      value: "pm",
    },
  ];


  const handleSubmit = async () => {
    // setLoading(true)
    if (formvalidate()) {
      console.log("Form is Valid")




      // if (handleFormValidation(couponName)) {
      // console.log("Title", title)
      // console.log("description", location.state.description)
      // console.log("bbbblogimg", location.state.blogsimgs[0]?.url)
      // console.log("Date",moment(date).format("DD MMM YYYY"))
      // console.log("idddd", location.state.blogId)

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let sTime = parseInt(starttime);
      let eTime = parseInt(endtime);
      if (meridiem.toString().toLowerCase() == 'pm') {
        sTime = parseInt(starttime) + 12;
      }
      if (meri.toString().toLowerCase() == 'pm') {
        eTime = parseInt(endtime) + 12;
      }

      if (sTime >= eTime) {
        alert("End Time Should be greater than Start Time")

      } else {
        let data = {
          name: batchName,
          description: description,
          status: status,
          start_date: moment(startDate).format("YYYY-MM-DDT00:00:00"),
          end_date: moment(endDate).format("YYYY-MM-DDT00:00:00"),
          shift: shift,
          start_time: starttime + meridiem,
          end_time: endtime + meri,
        }

        // "start_time", couponvalue,
        // "end_time", couponvalue,

        let requestoptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };

        await fetch(
          process.env.REACT_APP_BASE_URL + "/batch/addBatch",
          requestoptions
        )
          .then((response) => response.json())
          .then((res) => {
            // setLoading(false)
            console.log("-------Add Batch response----------", res);
            navigate("/batch");
            return res;
          })
          .catch((error) => {
            // setLoading(false)
            console.error("Error:", error);
          });
      }



    }

  };
  const formvalidate = () => {
    let IsformValid = true;

    if (!batchName) {
      IsformValid = false;
      console.log("1")
      setbatchNameError(true);
    } else if (batchName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      console.log("1")
      setError(true);
    }


    if (!description) {
      IsformValid = false;
      setdescriptionError(true);
    } else if (description.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }
    if (!status) {
      IsformValid = false;
      setstatusError(true);
    }

    if (!startDate) {
      IsformValid = false;
      setstartDateError(true);
    }
    if (!endDate) {
      IsformValid = false;
      setendDateError(true);
    }
    if (!status) {
      IsformValid = false;
      setstatusError(true);
    }
    if (!shift) {
      IsformValid = false;
      setshiftError(true);
    }
    if (!starttime) {
      IsformValid = false;
      setstarttimeError(true);
    }
    if (!endtime) {
      IsformValid = false;
      setendtimeError(true);

    }

    if (!meridiem) {
      IsformValid = false
      setMeridiemError(true);
    }


    if (!meri) {
      IsformValid = false;
      setMeriError(true);
    }


    return IsformValid;
  };



  return (
    <div>
      <MainSideBarContainer />
      <div>
        {/* {loading ? (
          <div className="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : ( */}
        <div className="content-wrapper">
          {/* <ContentWrapper headContent='Users' myCompo="" /> */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <button
                    className="btn btn-dark button"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                </div>
                <div className="col-sm-6">
                  <h1 className="m-0"></h1>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container-fluid">
              <div
                className="container-fluid pt-4 px-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  className="shadow-lg"
                  style={{
                    margin: 10,
                    width: "50%",
                    borderRadius: 10,
                    // justifyContent: "center",
                    // textAlign: "center",
                  }}
                >
                  <h1
                    className="mt-0"
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    Add Batch
                  </h1>
                  <Card.Body>
                    <div>
                      <div>
                        {/* <div className='blog-Image mt-3' style={{ position: 'relative' }}>
                                                    <img className='image-fluid' style={{ width: 200, height: 200 }} src={blogimg.length === 0 ? DefaultImage : blogimg[0]} />
                                                    <input accept='image/*' type="file" onChange={handleChange} style={{ marginLeft: 10 }} />
                                                </div> */}
                        <div className="coruse-name mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Batch Name
                          </label>
                          <input
                            className="form-control"
                            placeholder="Batch name"
                            error={batchName.trim().match(/[0-9+@#$&%!~]/)}
                            value={batchName}
                            onChange={(e) => {
                              if (!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\/g/\-/?<>,;`'""\\/]/) && e.target.value.trim()) { setBatchName(e.target.value) }
                              else if (e.target.value.length === 0) {
                                setBatchName(e.target.value)
                              }
                              batchName.length > 0 ? setbatchNameError(false) : setbatchNameError(true)
                            }
                            }
                            required
                          />

                        </div>
                        {batchNameError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please enter batch Name
                          </span>
                        ) : null}


                        <div className="course-description mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Batch Description
                          </label>
                          <input
                            className="form-control"
                            placeholder="Description"
                            error={description.match(/[0-9+@#$&%!~]/)}
                            value={description}
                            onChange={(e) => {
                              if (!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\/g/\-/?<>,;`'""\\/]/) && e.target.value.trim()) { setDescription(e.target.value) }
                              else if (e.target.value.length === 0) {
                                setDescription(e.target.value)
                              }
                              description.length > 0 ? setdescriptionError(false) : setdescriptionError(true)
                            }
                            }

                            required
                          />
                          {descriptionError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please enter batch description
                            </span>
                          ) : null}
                        </div>


                        <div className="coruse-name mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>status: </label> &nbsp;
                          <input
                            id="radio-item-1"
                            name="radio-item-1"
                            type="radio"
                            value="1"
                            onChange={(e) => {
                              handleRadioChange(e)
                              e.target.value ? setstatusError(false) : setstatusError(true)
                            }}
                          />
                          <label htmlFor="radio-item-1">Active</label> &nbsp;

                          <input
                            id="radio-item-2"
                            name="radio-item-1"
                            type="radio"
                            value="0"
                            onChange={(e) => {
                              handleRadioChange(e)
                              e.target.value ? setstatusError(false) : setstatusError(true)
                            }}

                          />
                          <label htmlFor="radio-item-2">
                            Inactive
                          </label>
                          {statusError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please select status
                            </span>
                          ) : null}

                        </div>

                        <div className="coruse-startDate mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Batch Start Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="startDate"
                            value={startDate}
                            onChange={(e) => {
                              setstartDate(e.target.value);
                              e.target.value ? setstartDateError(false) : setstartDateError(true);
                            }}
                            required
                          />
                          {startDateError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please enter start Date
                            </span>
                          ) : null}
                        </div>

                        <div className="endDate mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Batch End Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="endDate"
                            onChange={(e) => {
                              setendDate(e.target.value);
                              e.target.value ? setendDateError(false) : setendDateError(true);
                            }}
                            min={startDate}
                            required
                          />
                          {endDateError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please enter end Date
                            </span>
                          ) : null}
                        </div>

                        <div className="coruse-baseprice mt-3">
                          <label style={{ fontSize: 20, letterSpacing: 1 }}>
                            Shift
                          </label>
                          {/* <input className='form-control' placeholder='couponType' onChange={(e) => setcouponType(e.target.value)} required/> */}
                          <select
                            className="form-select" x
                            placeholder="shift"
                            value={shift}
                            onChange={(e) => {
                              setShift(e.target.value);
                              e.target.value ? setshiftError(false) : setshiftError(true);
                            }}
                          >
                            <option value="">--Choose--</option>
                            {shiftDropdown.map((item) => {
                              return (
                                <option key={item.value}
                                  value={item.value}

                                >
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>

                          {shiftError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please select shift
                            </span>
                          ) : null}

                        </div>



                        <Row className="mt-3">
                          <Col md={6} className='text-center'>
                            <h5>Start Time</h5>
                            <Row>
                              <Col md={6}>
                                <Form.Select onChange={(e) => {
                                  setStarttime(e.target.value)
                                  e.target.value ? setstarttimeError(false) : setstarttimeError(true)
                                }}  >
                                  <option value="">--Choose--</option>
                                  {shiftTime.map((item) => {
                                    return (
                                      <option
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    )
                                  })}
                                </Form.Select>


                                {starttimeError ? (
                                  <span style={{ color: "red", fontSize: 15 }}>
                                    *Please select start time
                                  </span>
                                ) : null}
                              </Col>



                              <Col md={6}>
                                <Form.Select onChange={(e) => {
                                  setMeridiem(e.target.value);
                                  e.target.value ? setMeridiemError(false) : setMeridiemError(true)
                                }}>
                                  <option value="">--Choose--</option>
                                  {shiftDropTime.map((item) => {
                                    return (
                                      <option
                                        value={item.value}

                                      >
                                        {item.label}
                                      </option>
                                    )
                                  })}
                                </Form.Select>

                                {meridiemError ? (
                                  <span style={{ color: "red", fontSize: 15 }}>
                                    *Please select meridiem
                                  </span>
                                ) : null}
                              </Col>

                            </Row>
                          </Col>


                          {/* ---- End Time Select ---- */}
                          <Col md={6} className='text-center'>
                            <h5>End Time</h5>
                            <Row>
                              <Col md={6}>
                                <Form.Select onChange={(e) => {
                                  setEndtime(e.target.value);
                                  e.target.value ? setendtimeError(false) : setendtimeError(true)
                                }} >
                                  <option value="">--Choose--</option>
                                  {shiftTime.map((item) => {
                                    return (
                                      <option
                                        value={item.value}

                                      >
                                        {item.label}
                                      </option>
                                    )
                                  })}
                                </Form.Select>
                                {endtimeError ? (
                                  <span style={{ color: "red", fontSize: 15 }}>
                                    *Please select end time
                                  </span>
                                ) : null}
                              </Col>



                              <Col md={6}>
                                <Form.Select onChange={(e) => {
                                  setMeri(e.target.value);
                                  e.target.value ? setMeriError(false) : setMeriError(true)
                                }} >

                                  <option value="">--Choose--</option>
                                  {shiftDropTime.map((item) => {
                                    return (
                                      <option
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    )
                                  })}
                                </Form.Select>
                                {meriError ? (
                                  <span style={{ color: "red", fontSize: 15 }}>
                                    *Please select meridiem
                                  </span>
                                ) : null}
                              </Col>


                            </Row>
                          </Col>
                        </Row>

                      </div>

                    </div>



                  </Card.Body>
                  <div className="d-flex m-3" style={{ justifyContent: "center", marginTop: "50px" }}>
                    <button
                      className="btn btn-primary button"
                      style={{
                        fontSize: 20,
                        marginBottom: "20px",
                        width: "30%",
                        justifyContent: "space-evenly"

                      }}

                      onClick={(e) => {
                        handleSubmit();
                      }}
                    >

                      Submit
                    </button>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default AddBatch;
