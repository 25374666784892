import React, { useEffect, useState } from 'react'
import MainSideBarContainer from './MainSideBarContainer'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';

const sortIcon = <ArrowDownward />;

function Enquiryform() {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [title, setTitle] = useState('')
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState()

    const getdata = async () => {
        setLoading(true)

        await fetch(process.env.REACT_APP_BASE_URL + '/enquiryform/list', {}).then(response => response.json()).then((res) => {
            setLoading(false)
            setData(res.data);
            setFilteredData(res.data);
            return res
        })
            .catch((error) => {
                setLoading(false)
                console.error('Error:', error);
            });

    }
    const filterData = (char) => {
        // setLoader(true);
        // console.log(char)
        // console.log("Charracter Array", filteredData)
        let f = filteredData?.filter(item => item?.fullname?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
            //item?.couponCode?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            item?.mobile?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            dayjs(item?.createdAt).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            //   dayjs(item?.endDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase())||
            item?.email?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            item?.city?.toString().toLowerCase()?.startsWith(char?.toLowerCase())
            //   item?.gramsevakhName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
            //   item?.serverNo?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) 
        )
        setData(f)
        console.log("MyData", f)
        // setLoader(false);
    }

    useEffect(() => {
        getdata()
    }, [])

    const columns = [
        {
            name:'No.',
            selector:(row,index) => index + 1 ,
            width:'7%'
        },
        {
            name: 'Full Name',
            selector: row => row.fullname,
            sortable: true,
            wrap: true
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
            wrap: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap: true
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
            wrap: true
        },
        {
            name: 'utm_source',
            selector: row => row.utm_source,
            sortable: true,
            wrap: true
        },
        {
            name: 'utm_campaign',
            selector: row => row.utm_campaign,
            sortable: true,
            wrap: true
        },
        {
            name: 'Date and Time',
            selector: row => moment(row.createdAt).format("DD MMM YYYY hh:mm a"),
            sortable: true,
            wrap: true
        },

    ];
    const options = {
        pagination: true,
        paginationPerPage: 10, // Set your desired items per page
        createRowId: (row, rowIndex, columnIndex, dataIndex) => rowIndex + 1,
      };

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                color: 'red',
                fontSize: '16px'
            },
        },
        cells: {
            style: {
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '10px',
                fontSize: '16px'
            },
        },
    };

    const downloadExcel = (customHeadings) => {
        let dataSet = [];
        console.log("dataset", dataSet)
        dataSet = customHeadings;
        const worksheet = XLSX.utils.json_to_sheet(dataSet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "EnquiryFormReport.xlsx");
    };

    const CustomExcel = () => {

        const customHeadings = data.length > 0 && data.map(item => ({
            "Name": item.fullname ? item.fullname : '-',
            "Mobile": item.mobile ? item.mobile : '-',
            "Email": item.email ? item.email : '-',
            "City": item.city ? item.city : '-',
            "utm_source": item.utm_source ? item.utm_source : '-',
            "utm_campaign": item.utm_campaign ? item.utm_campaign : '-',

            "Date and Time": item.createdAt ? moment(item.createdAt).format("DD MMM YYYY hh:mm a") : '-',
        }))
        console.log("Customm Excel", customHeadings)
        downloadExcel(customHeadings)
    };

    console.log("data", data)

    return (
        <div>
            <MainSideBarContainer active='enquiryform' />
            <div>
                <div className="content-wrapper">

                    {/* <ContentWrapper headContent='Users' myCompo="" /> */}
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Enquiry Form</h1>
                                </div>
                                <div className='col-sm-6'>
                                    <button className='btn btn-dark float-right mr-5 mt-1 button' onClick={CustomExcel}>Export Data</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="container-fluid pt-4 px-4">
                                <div className=''>
                                    <input type="text" placeholder="Search" onChange={(e) => { filterData(e.target.value) }} />
                                    <div className="row bg-light rounded justify-content-center p-3 mx-0">
                                        {loading ? (
                                            <div className="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <div className='col-md-12 lm-6 table-bordered' style={{ paddingLeft: '0px', paddingRight: '0px', }}>
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    sortIcon={sortIcon}
                                                    pagination
                                                    responsive
                                                    paginationPerPage={10}  // Set your desired items per page
                                                    options={options}

                                                //customStyles={customStyles}
                                                // theme="solarized"
                                                />
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Enquiryform