import React, { useEffect, useState } from 'react'
import DataTable, { Media, createTheme } from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MainSideBarContainer from './MainSideBarContainer'
import moment from 'moment'
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';



const sortIcon = <ArrowDownward />;
export default function Users() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState()
    const getdata = async () => {

        setLoading(true)
        await fetch(process.env.REACT_APP_BASE_URL+'/user/userslist', {}).then(response => response.json()).then((res) => {
            console.log("users",res.data)
            setLoading(false);
            setData(res.data);
            setFilteredData(res.data);
            return res.data
        })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
            });
        console.log("userlist____----", data)
    }

    const filterData = (char) => {        
        let f = filteredData?.filter(item => item?.first_name?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
          item?.couponCode?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          item?.mobile?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          dayjs(item?.createdAt).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||        
          item?.userId?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          item?.email?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          item.utm_source?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          item?.city?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) 
        
          )
          setData(f)
        console.log("MyData", f)
        // setLoader(false);
    }
    useEffect(() => {
        getdata()
    }, [])

    const columns = [
        {
            name: 'Sr.No',
            selector: row => row.userId,
            sortable: true,
            width :"8%"
        },
        {
            name: 'Name',
            selector: row => [row.first_name, " ", row.last_name],
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap:true
        },
        {
            name: 'UTM Source',
            selector: row => row.utm_source,
            sortable: true,
            wrap: true
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => moment(row.createdAt).format("DD MMM YYYY hh:mm a"),
            sortable: true,
            wrap:true
        },

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                color: 'red',
                fontSize: '16px'
            },
        },
        cells: {
            style: {
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '10px',
                fontSize: '16px'
            },
        },
    };

    const downloadExcel = (customHeadings) => {
        let dataSet = [];
        console.log("dataset",dataSet)
        dataSet = customHeadings ;
        const worksheet = XLSX.utils.json_to_sheet(dataSet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "UserListReport.xlsx");
    };
    
    const CustomExcel = () => {
      
        const customHeadings = data.length > 0 && data.map( item => ({
            "Sr.no":item.userId ? item.userId : '-',
            "Name":item.first_name && item.last_name ? item.first_name+" "+item.last_name : '-',
            "Mobile":item.mobile ? item.mobile : '-',
            "Email":item.email ? item.email : '-',
            "UTM Source":item.utm_source ? item.utm_source:'-',
            "City": item.city ? item.city : '-',
            "Date":item.createdAt ? moment(item.createdAt).format("DD MMM YYYY hh:mm a") : '-',
        }) )
        console.log("Customm Excel",customHeadings)
        downloadExcel(customHeadings)
    };

    return (
        <div>
            <MainSideBarContainer active='users' />
            <div>
                <div className="content-wrapper">

                    {/* <ContentWrapper headContent='Users' myCompo="" /> */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Users List</h1>
                                </div>
                                <div className='col-sm-6'>
                                    <button className='btn btn-dark float-right mr-5 mt-1 button' onClick={CustomExcel}>Export Data</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="container-fluid pt-4 px-4">
                                <div className=''>
                                <input type="text" placeholder= "Search" onChange={(e)=>{filterData(e.target.value)}} />
                                    <div className="row bg-light rounded justify-content-center p-3 mx-0">
                                    {loading ? (
                                         <div className="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                       </div>
                                         ) : (
                                        <div className='col-md-12 lm-6 table-bordered' style={{paddingLeft:'0px',paddingRight:'0px',}}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                sortIcon={sortIcon}
                                                pagination
                                                responsive
                                                // customStyles={customStyles}
                                            // theme="solarized"
                                            />
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
