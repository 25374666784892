import React, { useState, useEffect } from "react";
import MainSideBarContainer from "./MainSideBarContainer";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../img/Profile Admin.png";
import uploadImg from "../img/upload.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Compressor from "compressorjs";
import S3 from "react-aws-s3/dist/react-aws-s3";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";


window.Buffer = window.Buffer || require("buffer").Buffer;

const couponDropdown = [
  {
    label: "Value",
    value: 1,
  },
  {
    label: "Percentage",
    value: 2,
  },
];
const isPublishDropdown = [
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];

function AddCoupon() {
  const navigate = useNavigate();
  const [courseId, setcourseId] = useState();
  const [couponName, setcouponName] = useState("");
  const [couponCode, setcouponCode] = useState("");
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [couponType, setcouponType] = useState("");
  const [couponvalue, setcouponvalue] = useState();
  const [isPublish, setIsPublish] = useState("");
  const [loader, setLoader] = useState(false);
  
  const date = new Date();
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [couponError, setcouponError] = useState(false);
  const [couponCodeError, setcouponCodeError] = useState(false);
  const [courseError, setcourseError] = useState(false);
  const [couponstartdateError, setcouponstartdateError] = useState(false);
  const [couponEnddateError, setcouponEnddateError] = useState(false);
  const [coupontypeError, setcoupontypeError] = useState(false);
  const [couponvalueError, setcouponvalueError] = useState(false);
  const [isPublishError, setIsPublishError] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    await fetch(process.env.REACT_APP_BASE_URL+"/course/viewallcourses")
      .then((response) => response.json())
      .then((res) => {
        setData(res);
        console.log("courselist", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const formvalidate = () => {
    let IsformValid = true;
    if (!couponName) {
      IsformValid = false;
     setcouponError(true)
    // } else if (couponName.match(/[0-9+@#$&%!~]/)) {
    //   IsformValid = false;
    //   setError(true)
    }
    // if (!courseError) {
    //   IsformValid = false;
    //   setcourseError(true)
    // }

    if (!startDate) {
      IsformValid = false;
      setcouponstartdateError(true)
    }
    if (!endDate) {
      IsformValid = false;
      setcouponEnddateError(true)
    }
    if (!couponType) {
      IsformValid = false;
     setcoupontypeError(true)
    }
    if (!couponvalue) {
      IsformValid = false;
     setcouponvalueError(true)
    }
    if (!isPublish) {
      IsformValid = false;
     setIsPublishError(true)
    }
    


    return IsformValid;
  }




  const handleSubmit = async () => {
    // setLoading(true)
    
    if (formvalidate()) {
        console.log("Form is Valid")
       
   
  // if (handleFormValidation(couponName)) {
  // console.log("Title", title)
  // console.log("description", location.state.description)
  // console.log("bbbblogimg", location.state.blogsimgs[0]?.url)
  // console.log("Date",moment(date).format("DD MMM YYYY"))
  // console.log("idddd", location.state.blogId)

  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  let urlencoded = new URLSearchParams();
  urlencoded.append("courseId", courseId);
  urlencoded.append("couponName", couponName);
  urlencoded.append("couponCode", couponCode);
  urlencoded.append("startDate", startDate);
  urlencoded.append("endDate", endDate);
  urlencoded.append("couponType", couponType);
  urlencoded.append("couponvalue", couponvalue);
  urlencoded.append("isPublish", isPublish);

  let requestoptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  await fetch(
    process.env.REACT_APP_BASE_URL+"/coupon/addcoupon",
    requestoptions
  )
    .then((response) => response.json())
    .then((res) => {
      setLoading(false)
      console.log("-------Add coupon response----------", res);
    navigate("/couponlist")
      return res;
    })
    .catch((error) => {
      // setLoading(false)
      console.error("Error:", error);
    });
  
  }
  else {
    console.log("Form is Invalid")
  }
};


  // }

  const uploadImageToS3 = (event, type) => {
    setLoader(true);
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + type,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    let fileInput = false;
    const image = event.target.files[0];
    console.log("image data before compression-------------- ", image);
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.7,
        success: (compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = getTimeStamp + "_" + "bawd";
          console.log("------- filename image upload --------", newFileName);

          const ReactS3Client = new S3(config);

          ReactS3Client.uploadFile(compressedResult, newFileName)
            .then((res) => {
              // blogimg.push(res.location)
              // setBlogimg(blogimg);
              // console.log("uploded image", res);
              // console.log("image uploaded successfully!!!!");
              // setLoader(false)
            })
            .catch((err) => {
              console.error(err);
            });
        },
      });
      console.log("--------------- Upload image -----------");
    }
  };



  return (
    <div>
      <MainSideBarContainer />
      <div>
      {loading ? <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                       </div> : <div className="content-wrapper">
          {/* <ContentWrapper headContent='Users' myCompo="" /> */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <button className="btn btn-dark button" onClick={() => navigate(-1)}>
                    Back
                  </button>
                </div>
                <div className="col-sm-6">
                  <h1 className="m-0"></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div
                className="container-fluid pt-4 px-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card className="shadow-lg" style={{borderTopLeftRadius:"10px"}}>
                  <h1 className="mt-3" style={{fontSize:"30px"}}>
                    ADD COUPON
                  </h1>
                  <Card.Body>
                    <div>
                      <div>
                        {/* <div className='blog-Image mt-3' style={{ position: 'relative' }}>
                                                    <img className='image-fluid' style={{ width: 200, height: 200 }} src={blogimg.length === 0 ? DefaultImage : blogimg[0]} />
                                                    <input accept='image/*' type="file" onChange={handleChange} style={{ marginLeft: 10 }} />
                                                   
                                                </div> */}
                        <div className="coruse-name mt-3">
                          <label>
                            Coupon Name<span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder="couponName"
                            error={couponName.match(/[0-9+@#$&%!~]/)}
                            onChange={(e) => setcouponName(e.target.value)}
                          
                            required
                          />
                        </div>
                            {
                              couponError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter coupon Name
                                </span>
                              ) : null
                            }


                        <div className="coruse-name mt-3">
                          <label>
                            Coupon Code<span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter coupon code"
                            error={couponName.match(/[0-9+@#$&%!~]/)}
                            onChange={(e) => setcouponCode(e.target.value)}                          
                            required
                          />
                        </div>
                           {
                              couponCodeError ? (
                                <span style={{ color: "red", fontSize: 15 }}>
                                  Please Enter coupon code
                                </span>
                              ) : null
                            }
                        
                        <div className="courselist mt-3">
                          <label
                            htmlFor="Collegename"
                            className="control-label"
                          >
                            
                              Course Name<span style={{ color: "#ff0000" }}>*</span>
                          </label>

                          <select
                            className="form-select"
                            onChange={(e) => setcourseId(e.target.value)}
                            required={true}
                          >
                            <option>--Choose Course--</option>
                            {data.map((e, key) => {
                              return (
                                <option key={key} value={e.courseId}>
                                  {e.courseName}
                                </option>
                              );
                            })}
                          </select>
                          {courseError ? <span style={{ color: 'red', fontSize: 15 }}>*please Enter Value</span> : null}
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-6">
                            <div className="course-description mt-3">
                              <label>
                                Start Date<span style={{ color: "#ff0000" }}>*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="startDate"
                                onChange={(e) => setstartDate(e.target.value)}
                                required
                              
                              />
                            {couponstartdateError ? <span style={{ color: 'red', fontSize: 15 }}>*Password cannot be empty</span> : null}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-6">
                            <div className="endDate mt-3">
                              <label>
                                End Date<span style={{ color: "#ff0000" }}>*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="endDate"
                                onChange={(e) => setendDate(e.target.value)}
                                required
                              

                              />
                              {couponEnddateError ? <span style={{ color: 'red', fontSize: 15 }}>*Password cannot be empty</span> : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-6">
                            <div className="coruse-baseprice mt-3">
                              <label>
                                Coupon Type<span style={{ color: "#ff0000" }}>*</span>
                              </label>
                              {/* <input className='form-control' placeholder='couponType' onChange={(e) => setcouponType(e.target.value)} required/> */}
                              <select
                                className="form-select"
                                placeholder="couponType"
                                onChange={(e) => setcouponType(e.target.value)}
                              >
                                <option value="">--Choose--</option>
                                {couponDropdown.map((item) => {
                                  return (
                                    <option
                                      value={item.value}
                                      onChange={(e) =>
                                        setcouponType(e.target.value)
                                      }
                                    >
                                      {item.label}
                                    </option>
                                  );
                                })}
                              </select>
                            {coupontypeError ? <span style={{ color: 'red', fontSize: 15 }}>Select value</span> : null}
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-6">
                            <div className="coruse-offerprice mt-3">
                              <label>
                                Coupon Value<span style={{ color: "#ff0000" }}>*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="couponvalue"
                                onChange={(e) => setcouponvalue(e.target.value)}
                                required
                              />
                              {couponvalueError ? <span style={{ color: 'red', fontSize: 15 }}>Enter coupon value</span> : null}
                            </div>
                          </div>
                        </div>

                        <div className="is-publish mt-3">
                          <label> Do you want to publish the coupon?<span style={{ color: "#ff0000" }}>*</span></label>
                          <select
                            className="form-select"
                            onChange={(e) => setIsPublish(parseInt(e.target.value))}
                            value={isPublish}
                            required
                          >
                          <option value="">--Choose--</option>
                          {isPublishDropdown.map((item) => {
                            return (
                              <option
                                value={item.value}
                                onChange={(e) =>
                                  setIsPublish(e.target.value)
                                }
                              >
                                {item.label}
                              </option>);
                          })}
                          </select>
                          {isPublishError ? <span style={{ color: 'red', fontSize: 15 }}>Select value</span> : null}
                        </div>

                      </div>
                    </div>
                  </Card.Body>

                  <div class="submit-button">
                    <button
                      className="btn btn-primary button"
                      style={{
                        fontSize: 20,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                        width:"100px",
                        marginBottom:"50px",
                        marginTop:"30px",
                        justifyContent:"center",
                        textAlign:"center"
                      }}
                      onClick={(e) => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </button>                  
                  </div>
                  
                </Card>
              </div>
            </div>
          </div>
        </div>}
       
      </div>
    </div>
  );
}

export default AddCoupon;
