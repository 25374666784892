import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MainSideBarContainer from './MainSideBarContainer'
import moment from 'moment';
import NOImagehere from '../img/nope-not-here.webp'
// import { ArrowLeft } from '@material-ui/icons';

function ViewBlog() {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Props", location.state)
    }, [])

    const description = location.state.description

    return (
        <div>
            <MainSideBarContainer />
            <div>
                <div className="content-wrapper">
                    {/* <ContentWrapper headContent='Users' myCompo="" /> */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">View Blog</h1>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <button className='btn btn-dark' onClick={ () => navigate(-1) } >Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="container-fluid pt-4 px-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div class="mt-11">
                                    <div class="container p-2">
                                        <h3>{location.state.title}</h3> 
                                        <h3>{location.state.ispublished}</h3>
                                        <div class="card mb-3 ">
                                           {location.state.blogsimgs[0]?.url === undefined ?
                                            <img style={{ width:'300',height:'300' }} src={NOImagehere} />
                                           : <img style={{ width:'900',height:'intrinsic' }} src={location.state.blogsimgs[0]?.url} />}
                                                <div class="card-body">
                                                    <p class="card-text"><small class="text-muted">Created at {moment(location.state.createdAt).format('DD MMM YYYY hh:mm a')}</small></p>
                                                    <div dangerouslySetInnerHTML={{__html:description}} />
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewBlog