import React, { useEffect, useState } from 'react'
import MainSideBarContainer from './MainSideBarContainer'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from 'axios';
import dayjs from 'dayjs';

import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";


const sortIcon = <ArrowDownward />;

function Couponlist() {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    
    const [appliedCoupon, setappliedCoupon] = useState([])
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [filteredData, setFilteredData] = useState()

    const [show, setShow] = useState(false);
    const [filterCode,setFilterCode] = useState('')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handle = (data) => {
        console.log("-----------handle code-----------",data)
        handleShow()
        setFilterCode(data)
    }


    const filterData = (char) => {
        // setLoader(true);
        // console.log(char)
        // console.log("Charracter Array", filteredData)
        let f = filteredData?.filter(item => item?.couponName?.toLowerCase()?.startsWith(char?.toLowerCase()) ||
          item?.couponCode?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          item?.couponvalue?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          dayjs(item?.startDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          dayjs(item?.endDate).format('DD-MM-YYYY').toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
          item?.isPublish?.toString().toLowerCase() === char?.toLowerCase()
        //   item?.description?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
        //   item?.sarpanchName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
        //   item?.gramsevakhName?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
        //   item?.serverNo?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) 
          )
          setData(f)
        console.log("MyData", f)
        // setLoader(false);
      }

    const getdata = async () => {
        setLoading(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/coupon/viewallcoupon').then(response => response.json()).then((res) => {
            setLoading(false);
            setData(res);
            setFilteredData(res);

            return res
        })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
            });

    }


    const getcoupondata = async () => {
        // setLoader(true)
        let currentComponent = this;
        const appliedCouponlist = await axios.get(process.env.REACT_APP_BASE_URL + "/appliedCoupon/getAllAppliedCoupon")
            .then(res => {
                setappliedCoupon(res.data.data);
                console.log("resssssssssssssss", res.data)
                // setLoader(false)
            })
            .catch((err) => {
                console.log(err);
                // setLoader(false)
            })
    }


    useEffect(() => {
        getdata()
        getcoupondata()
    }, [])


    const columns = [
        {
            name: 'No.',
            selector: (row, index) => index + 1 ,
            width:'7%'
            
        },
        {
            name: 'Coupon name',
            selector: row => row?.couponName,
            sortable: true,
            wrap: true
        },
        {
            name: 'Coupon code',
            selector: row => row?.couponCode,
            sortable: true,
            wrap: false
        },
        {
            name: 'Coupon value',
            selector: row => row?.couponvalue,
            sortable: true,
            wrap: false,           
        },

        {
            name: 'Start date',
            selector: row => moment(row?.startDate).format("DD MMM YYYY hh:mm a"),
            sortable: true,
            wrap: true
        },
        {
            name: 'End date',
            selector: row => moment(row?.endDate).format("DD MMM YYYY hh:mm a"),
            sortable: true,
            wrap: true
        },
        {
            name:'IsPublish',
            selector:row =>row.isPublish,
            sortable: true,
            wrap:false
        },
        {
            name: 'Action',
            selector: row => <div>
                <button className='btn btn-sm btn-outline-primary mt-1 ' onClick={() => { navigate("/View", { state: row }) }}>View</button>
                <button className='btn btn-sm btn-outline-success mt-1 ml-1 ' onClick={() => { navigate("/Update-coupon", { state: row }) }}>edit</button>
                <button className='btn btn-sm btn-outline-dark mt-1 ml-1 '  onClick={() =>{handle(row.couponCode)}}> Applied list </button>
                
            </div>,
            sortable: true,
            wrap: true
        }
    ];


    const columns1 = [
        {
            name: 'No.',
            selector: (row, index) => index + 1,
            width:'8%'
        },
        {
            name: 'Coupon Code',
            selector: row => row?.couponCode,
            sortable: true,
            wrap: true
        },
        // {
        //     name: 'Used On',
        //     selector: row => moment(row?.createdAt).format("DD MMM YYYY"),
        //     sortable: true,
        //     wrap: true
        // },
        {
            name: 'Name',
            selector: row =>[row?.first_name, " ", row?.last_name],
            sortable: true,
            wrap: true
        },

        {
            name: 'Mobile',
            selector: row =>row?.mobile,
            sortable: true,
            wrap: true
        },
    ];

    const customStyles = {
        headRow:{
            style: {
                backgroundColor:'#090671',
                padding:'0px',

            }
        },
        rows: {
            style: {
                minHeight: '50px', // override the row height      
                padding:'0px',                        
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                color: 'white',
                fontSize: '16px',
                textAlign:'center',
                boredrCollapse:'separate',
                boredrSpacing:'2px'                
            },
        },
        cells: {
            style: {               
                paddingLeft: '10px', // override the cell padding for data cells
                paddingRight: '10px',
                fontSize: '16px',
                boredrCollapse:'separate',
                boredrSpacing:'2px'
            },
        },
    };

    const downloadExcel = (customHeadings) => {
        let dataSet = [];
        console.log("dataset", dataSet)
        dataSet = customHeadings;
        const worksheet = XLSX.utils.json_to_sheet(dataSet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "CouponReport.xlsx");
    };

    const CustomExcel = () => {

        const customHeadings = data.length > 0 && data.map(item => ({

            "Name": item.couponName ? item.couponName : '-',
            "Mobile": item.couponCode ? item.couponCode : '-',
            // "Course Name":item.course_name ? item.course_name : '-',
            "Start Date": item.startDate ? moment(item.startDate).format("DD MMM YYYY hh:mm a") : '-',
            "End Date": item.endDate ? moment(item.endDate).format("DD MMM YYYY hh:mm a") : '-',

            "CouponValue": item.couponvalue ? item.couponvalue : '-',
            "IsPublish" : item.isPublish ? item.isPublish : '-',
        }))
        console.log("Customm Excel", customHeadings)
        downloadExcel(customHeadings)
    };


    return (
        <div>
            <MainSideBarContainer active='coupon' />
            <div>
                <div className="content-wrapper">

                    {/* <ContentWrapper headContent='Users' myCompo="" /> */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{paddingLeft:'35px'}}>Coupon List</h1>
                                </div>
                                <div className='col-sm-6'>
                                    <button className='btn btn-dark float-right mr-5 mt-1 button' onClick={() => navigate('/Coupon-form')}>Add Coupon</button>
                                    <button className='btn btn-dark float-right mr-2 mt-1 button' onClick={CustomExcel}>Export Data</button>

                                </div>
                                {/* <div className='col-sm-6'>
                                    <button className='btn btn-dark float-right mr-5 mt-1' onClick={CustomExcel}>Export Data</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="container-fluid pt-4 px-4">
                                {/*  */}
                                <input type="text" placeholder= "Search" onChange={(e)=>{filterData(e.target.value)}}  style={{marginLeft:'15px',padding:'5px',borderRadius:'5px'}}/>
                                <div className=''>
                                    <div className="row bg-light rounded justify-content-center p-3 mx-0">
                                        {loading ? (
                                            <div className="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <div className='col-md-12 lm-6 table-bordered' style={{paddingLeft:'0px',paddingRight:'0px',}}>
                                                <DataTable
                                                    columns={columns}
                                                    data={data}
                                                    sortIcon={sortIcon}
                                                    pagination
                                                    responsive
                                                    //  customStyles={customStyles}
                                                // theme="solarized"
                                                />
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal style={{marginTop:"48px",height:"90%"}} show={show} onHide={handleClose} size="lg"  >
                        <Modal.Header closeButton={true}>
                            <Modal.Title>Applied Coupon List</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {console.log("Modal data",appliedCoupon)}
                            {console.log("filter Data", appliedCoupon.filter( item => item?.couponCode === filterCode ))}
                            {
                                //let filtercoupon = appliedCoupon.filter( item => item?.couponName === filterCode);
                                appliedCoupon.length > 0 ? <DataTable
                                    columns={columns1}
                                    data={appliedCoupon.filter( item => item?.couponCode === filterCode )}
                                    sortIcon={sortIcon}
                                    pagination
                                    responsive
                                    // customStyles={customStyles}
                                // theme="solarized"
                                /> : 'Never used this coupon'
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default Couponlist