import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useState } from 'react'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { FaMobileAlt } from 'react-icons/fa'

function Login() {

    const [mobile, setMobile] = useState('')
    const [password,setPassword] = useState('')
    const [error, setError] = useState(false)
    const [isvisible, setIsvisible] = useState(false)

    const navigate = useNavigate()

    const handleLogin = async () => {

        console.log("Mobile",mobile)
        console.log("Password",password)

        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

        let urlencoded = new URLSearchParams();
        urlencoded.append("mobile",mobile)
        urlencoded.append("password",password)

        let requestoptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        await fetch(process.env.REACT_APP_BASE_URL+"/user/login", requestoptions).then(response => response.json()).then((res) => {

            console.log("-------Add Blog API response----------",res)
            console.log(res)
            if(res.message === "Login Successfully"){
                localStorage.setItem("MahaReraUser",JSON.stringify(res.data))
                navigate("/")
            }
            else {
                setError(true)
            }
            return res;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
        
    }

    return (

        <div className='container-fluid' style={{ height: '100vh', display: 'flex', justifyContent: 'center', backgroundColor: '#343a40', alignItems: 'center' }}>
            <Card style={{ backgroundColor: 'white', padding: 30, fontSize: 20,width:400 }} >
                <Card.Header><h2 style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold' }}>Login</h2></Card.Header>
                <Card.Body>
                    <Form >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Mobile Number</Form.Label>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid ', borderColor: '#e8f0fe', backgroundColor: '#e8f0fe', borderRadius: 5 }}>
                                <Form.Control type="text" maxLength={10} pattern='[0-9]{0,10}'  placeholder="Enter Mobile Number" value={mobile}   
                                helperText={mobile.length<=9 || mobile.match(/[^0-9]/g) ? 'Please enter valid mobile No.' : ''} onChange={ (e) => { e.preventDefault();setMobile(e.target.value.replace(/[^0-9]/g, ""))  } } />
                                <FaMobileAlt style={{ fontSize: 26, marginLeft: 5, marginRight: 5 }} />
                            </div>
                            {/* { mobile.length === 10 ? null : <span style={{ color: '#003366', fontSize: 17,cursor:'pointer' }} >Invalid Mobile Number</span> } */}
                            {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid ', borderColor: '#e8f0fe', backgroundColor: '#e8f0fe', borderRadius: 5 }}>
                                <Form.Control type={isvisible ? 'text' : "password"} placeholder="Password" value={password} onChange={ (e) => { e.preventDefault(); setPassword(e.target.value) } } />
                                {isvisible ? <MdVisibilityOff style={{ fontSize: 26, marginLeft: 5, marginRight: 5,cursor:'pointer' }} onClick={() => setIsvisible(!isvisible)} /> : <MdVisibility style={{ fontSize: 26, marginLeft: 5, marginRight: 5,cursor:'pointer' }} onClick={() => setIsvisible(!isvisible)} />}
                            </div>
                        </Form.Group>
                        <Button variant="primary" type="submit" className='w-100' onClick={ (e) => { e.preventDefault();handleLogin() } }>
                            Login
                        </Button>
                        { error ? <h5 style={{ color:'red' }}>Invalid Credentials</h5> : null }
                        <Form.Group className=" mt-2" controlId="formBasicCheckbox" style={{ float: 'right' }}>
                            <h5 style={{ color: '#003366', fontSize: 17,cursor:'pointer' }} onClick={() => navigate('/forgot-password')}>Forgot Password</h5>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </div>

    )
}

export default Login