import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom'
import Dashboard from './Components/Dashboard'
import Users from './Components/Users';
import Webinar from './Components/Webinar';
import ContactedUs from './Components/ContactedUs'
import Payments from './Components/Payments';
import Blog from './Components/Blog';
import MainLayout from './layout/MainLayout';
import AddBlog from './Components/AddBlog';
import ViewBlog from './Components/ViewBlog';
import Login from './Components/Login';
import ForgotPassword from './Components/ForgotPassword';
import ChangePassword from './Components/ChangePassword';
import Enquiryform from './Components/Enquiryform';
import Coupon from './Components/Coupon';
import Couponlist from './Components/Couponlist';
import Editblog from './Components/Editblog';
import AddCourse from './Components/AddCourse';
import Courselist from './Components/Courselist';
import AppliedCouponList from './Components/AppliedCouponList';
import GoogleLink from './Components/GoogleLink';

import ViewCourse from './Components/ViewCourse';
import ViewCoupon from './Components/ViewCoupon';
import EditCoupon from './Components/EditCoupon';
import UpdateCourse from './Components/UpdateCourse';
import Batchlist from './Components/Batchlist';
import AddBatch from './Components/AddBatch';
import Editbatch from './Components/Editbatch'
function App() {
  return (
    <div>
      <Routes>
        <Route path='/login' element={ <Login /> } />
        <Route path="/forgot-password" element={ <ForgotPassword /> } />
        <Route path="/change-password" element={ <ChangePassword /> } />
         <Route path='/' element={<MainLayout />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='/webinar' element={<Webinar />} />
          <Route path='/contactedUs' element={<ContactedUs />} />
          <Route path='/payments' element={<Payments />} />
            <Route path='/users' element={<Users />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/addblog' element={ <AddBlog /> } />
          <Route path='/view-blog' element={ <ViewBlog /> } />
          <Route path="/enquiry-form" element={ <Enquiryform /> } />
          <Route path="/Coupon-form" element={ <Coupon /> } />
          <Route path="/couponlist" element={<Couponlist/>}/>
          <Route path="/appliedcouponlist" element={<AppliedCouponList/>}/>
          <Route path="/editblog" element={<Editblog/>}/>
          <Route path="/addcourse" element={<AddCourse/>}></Route>
          <Route path="/Courselist" element={<Courselist/>}></Route>
          <Route path="/GoogleLink" element={<GoogleLink/>} />
          
          
          <Route path="/ViewCourse" element={<ViewCourse/>}></Route>
          <Route path="/View" element={<ViewCoupon/>}></Route>
          <Route path="/Update-coupon" element={<EditCoupon/>}></Route>
          <Route path="/update" element={<UpdateCourse/>}></Route>
          <Route path="/batch" element={<Batchlist/>}></Route>
          <Route path="/addbatch" element={<AddBatch/>}></Route>
          <Route path="/updatebatch" element={<Editbatch/>}></Route>

        </Route>
      </Routes>
    </div>
  );
}

export default App;
