import React, { useState,useEffect } from 'react'
import MainSideBarContainer from './MainSideBarContainer'
import { Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultImage from '../img/Profile Admin.png'
import uploadImg from '../img/upload.png'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import Compressor from 'compressorjs';
import S3 from 'react-aws-s3/dist/react-aws-s3'
import moment from 'moment'
import axios from "axios";


window.Buffer = window.Buffer || require('buffer').Buffer

function Viewcourse() {

    const navigate = useNavigate()
    const location = useLocation();
    const [data, setData] = useState([])
    const [courseName, setcourseName] = useState('')
    const [courseDes, setcourseDes] = useState('')
    const [courseType, setcourseType] = useState('')
    const [basePrice, setbasePrice] = useState('')
    const [offerPrice, setofferPrice] = useState('')
    const [courseDuration,setcourseDuration] = useState('')
    const [startDate,setstartDate] = useState('')
    const [endDate,setendDate] = useState('')
    const [lastRegistrationDate,setlastRegistrationDate] = useState('')
    const  [courseTag,setcourseTag] = useState('')
    const [courseImgURL ,setcourseImgURL] = useState([])
    const [loader, setLoader] = useState(false)
    const date = new Date()
   



    const getdata = async () => {
        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

        let urlencoded = new URLSearchParams();
       
        urlencoded.append("courseId",location.state.courseId)


        let requestoptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        await fetch(process.env.REACT_APP_BASE_URL+"/course/viewsinglecourses",requestoptions).then(response => response.json()).then((res) => {
            console.log("-------view course API response----------",res)
            setData(res);
            // setcouponName(res.couponName)

                // navigate("/blog")
                return res;   
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        console.log("Props", location.state)
     getdata()
    }, [])

    // const handleSubmit = async () => {
     

    //     let myHeaders = new Headers()
    //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    //     let urlencoded = new URLSearchParams();
       
    //     urlencoded.append("courseName", courseName);
    //     urlencoded.append("courseDes", courseDes);
    //     urlencoded.append("courseType", courseType);
    //     urlencoded.append("basePrice", basePrice);
    //     urlencoded.append("offerPrice",offerPrice);
    //     urlencoded.append("courseDuration",courseDuration);
    //     urlencoded.append("startDate", startDate);
    //     urlencoded.append(" endDate",endDate);
    //     urlencoded.append("lastRegistrationDate",lastRegistrationDate);
    //     urlencoded.append("courseTag",courseTag);
    //     urlencoded.append("courseImgURL",courseImgURL);

        



    //     let requestoptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: urlencoded,
    //         redirect: 'follow'
    //     }

       

    //     await axios.post(process.env.REACT_APP_BASE_URL+"/course/addcourses",requestoptions).then(response => response.json()).then((res) => {
    //             console.log("-------Add course API response----------",res.data)
    //             navigate("/Courselist")
    //             return res;   
    //     })
    //         .catch((error) => {
    //             console.error('Error:', error);
    //         });
    // }

    const handleChange = async e => {
        if (e.target.files.length) {
            console.log("ENV", process.env.REACT_APP_BUCKET_NAME)
            const img = URL.createObjectURL(e.target.files[0]);
            uploadImageToS3(e, "courseImgURL")
        }
    };

    const uploadImageToS3 = (event, type) => {
        setLoader(true)
        const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            dirName: process.env.REACT_APP_DIR_NAME + type,
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            s3Url: process.env.REACT_APP_S3URL
        };

        let fileInput = false;
        const image = event.target.files[0];
        console.log("image data before compression-------------- ", image);
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            new Compressor(image, {
                quality: 0.7,
                success: (compressedResult) => {
                    let getTimeStamp = new Date().getTime();
                    let newFileName = getTimeStamp + "_" + 'bawd';
                    console.log("------- filename image upload --------", newFileName);

                    const ReactS3Client = new S3(config);

                    ReactS3Client.uploadFile(compressedResult, newFileName).then((res) => {
                       courseImgURL.push(res.location)
                        setcourseImgURL(courseImgURL);
                        console.log("uploded image", res);
                        console.log("image uploaded successfully!!!!");
                        setLoader(false)
                    }).catch(err => {
                        console.error(err);
                    });
                },
            });
            console.log("--------------- Upload image -----------");
        }
    };

    return (
        <div>
            <MainSideBarContainer />
            <div>
                <div className="content-wrapper">
                    {/* <ContentWrapper headContent='Users' myCompo="" /> */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                    <button className='btn btn-dark button' onClick={ () => navigate(-1) } >Back</button>
                                </div>
                                <div className="col-sm-6">
                                    <h1 className="m-0"></h1>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container-fluid">
                            <div className="container-fluid pt-4 px-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Card
                                    className="shadow-lg"
                                    style={{
                                        margin: 10,
                                        width: '80%',
                                        borderRadius: 10,
                                        // justifyContent: "center",
                                        // textAlign: "center",
                                    }}

                                >
                                    <h1 className="mt-3"style={{justifyContent: "center",textAlign: "center"}}>VIEW COURSE</h1>
                                    <Card.Body>
                                        <div>
                                            <div>
                                                {/* <div className='blog-Image mt-3' style={{ position: 'relative' }}>
                                                    <img className='image-fluid' style={{ width: 200, height: 200 }} src={blogimg.length === 0 ? DefaultImage : blogimg[0]} />
                                                    <input accept='image/*' type="file" onChange={handleChange} style={{ marginLeft: 10 }} />
                                                   
                                                </div> */}
                                                <div className='coruse-name mt-3'>
                                                    <label style={{ fontSize: 20, letterSpacing: 1 }}>Course Name:{data.courseName}</label>
                                                    {/* <input className='form-control' placeholder='courseName' value={data.courseName}/> */}
                                                </div>
                                                <div className='course-description mt-3'>
                                                    <label style={{ fontSize: 20, letterSpacing: 1 }}>Course Description:{data.courseDes}</label>
                                                    {/* <input className='form-control' placeholder='courseDes' value={data.courseDes}  /> */}
                                                </div>
                                                <div className='coruse-coursetype mt-3'>
                                                    <label style={{ fontSize: 20, letterSpacing: 1 }}>Course Type:{data.courseType} </label>
                                                    {/* <input className='form-control' placeholder='courseType'value={data.courseType}  /> */}
                                                </div>
                                                <div className='coruse-baseprice mt-3'>
                                                    <label style={{ fontSize: 20, letterSpacing: 1 }}>Course BasePrice:{data.basePrice}</label>
                                                    {/* <input className='form-control' placeholder='basePrice' value={data.basePrice}  /> */}
                                                </div>
                                                <div className='coruse-offerprice mt-3'>
                                                    <label style={{ fontSize: 20, letterSpacing: 1 }}>Course OfferPrice:{data.offerPrice}</label>
                                                    {/* <input className='form-control' placeholder='offerPrice' value={data.offerPrice} /> */}
                                                </div>
                                                <div className='coruse-startDate mt-3'>
                                                    <label style={{ fontSize: 20, letterSpacing: 1 }}>Course StartDate:{moment(data.startDate).format("DD MMM YYYY hh:mm a")}</label>
                                                    {/* <input className='form-control' placeholder='startDate'  value={moment(data.startDate).format("DD MMM YYYY hh:mm a")} /> */}
                                                </div>
                                                <div className='endDate mt-3'>
                                                    <label style={{ fontSize: 20, letterSpacing: 1 }}>Course EndDate:{moment(data.endDate).format("DD MMM YYYY hh:mm a")}</label>
                                                    {/* <input  className='form-control' placeholder='endDate' value={moment(data.endDate).format("DD MMM YYYY hh:mm a")} /> */}
                                                </div>
                                                <div className='lastRegistrationDate mt-3'>
                                                    <label style={{ fontSize: 20, letterSpacing: 1 }}>Last RegistrationDate:{moment(data.lastRegistrationDate).format("DD MMM YYYY hh:mm a")}</label>
                                                    {/* <input  className='form-control' placeholder='lastRegistrationDate' value={moment(data.lastRegistrationDate).format("DD MMM YYYY hh:mm a")} /> */}
                                                </div>
                                                <div className='courseDuration mt-3'>
                                                    <label style={{ fontSize: 20, letterSpacing: 1 }}>Course Duaration:{data.courseDes}</label>
                                                    {/* <input className='form-control' placeholder='courseDuration' value={data.courseDes} /> */}
                                                </div>
                                                <div className='coruse-tag mt-3'>
                                                    <label style={{ fontSize: 20, letterSpacing: 1 }}>Course Tag:{data.courseTag}</label>
                                                    {/* <input className='form-control' placeholder='coursetag' value={data.courseTag} /> */}
                                                </div>
                                                <div className='course-Image mt-3' style={{ position: 'relative' }}>
                                                    <img className='image-fluid' style={{ width: 200, height: 200 }} />
                                                    <input accept='image/*' type="file" onChange={handleChange} style={{ marginLeft: 10 }} />
                                                    {/* <div style={{ position:'absolute',backgroundColor:'transparent',height:'100%',width:'100%',zIndex:2 }}>
                                                       <img src={uploadImg} style={{ width:100,height:100}} />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                    
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Viewcourse;