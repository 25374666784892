import React, { useEffect, useState } from 'react'
// import User from './User'

function ContentWrapper(props) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getdata()
    }, []);

    
    const getdata = async () => {
        setLoading(true);
        await fetch(process.env.REACT_APP_BASE_URL+'/dashboard/count').then(response => response.json()).then((res) => {
            setLoading(false);
            setData(res);
            console.log("------getdata---------",data.data);
            return res
        })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
            });

    }


    return (
        <div>
            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <div className="content-header">
                    <div className="container-fluid">
                        {/* Heading */}
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">{props.headContent}</h1>
                            </div>
                            
                        </div>
                        {/* <!-- /.row --> */}
                        {/* <!-- Small boxes (Stat box) --> */}
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/* <!-- small box --> */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{data.user?data.user:0}</h3>

                                        <p>All Users</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-bag"></i>
                                    </div>
                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                                </div>
                            </div>
                            {/* <!-- ./col --> */}
                            <div className="col-lg-3 col-6">
                                {/* <!-- small box --> */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{data.user?data.user:0}</h3>

                                        <p>All Transaction</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars"></i>
                                    </div>
                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                                </div>
                            </div>
                            {/* <!-- ./col --> */}
                            <div className="col-lg-3 col-6">
                                {/* <!-- small box --> */}
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>3</h3>

                                        <p>All Blog</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                                </div>
                            </div>
                            {/* <!-- ./col --> */}
                            <div className="col-lg-3 col-6">
                                {/* <!-- small box --> */}
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>4</h3>

                                        <p>All courses</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-pie-graph"></i>
                                    </div>
                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className="">
                            {props.myCompo === "" ? "" : <props.myCompo data={props.data} prevLoc={props.prevLoc} />}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContentWrapper